import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import ArrowButton from '../../generic/buttons/ArrowButton';
import ModalCard from '../../generic/ModalCard';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

const LocationDetailsGalleryModal = ({
  images,
  onClose,
  isOpen,
  defaultIndex = 0,
}) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [slideIndex, setSlideIndex] = useState(defaultIndex);
  const onSlide = (data) => {
    setSlideIndex(data.activeIndex);
  };

  return (
    <ModalCard onClose={onClose} isOpen={isOpen} size="size-full md:size-5/6">
      <div className="w-full relative h-[80%]">
        <ArrowButton
          className="custom-prev-gallery absolute -left-24 top-1/2 -translate-y-1/2 transform hidden md:flex"
          orientation="-rotate-90"
          isDisabled={slideIndex === 0}
        />
        <ArrowButton
          className="custom-next-gallery absolute -right-24 top-1/2 -translate-y-1/2 transform hidden md:flex"
          orientation="rotate-90"
          isDisabled={slideIndex === images.length - 1}
        />
        <Swiper
          onSlideChange={onSlide}
          initialSlide={defaultIndex}
          style={{
            '--swiper-navigation-color': '#000',
            '--swiper-pagination-color': '#000',
          }}
          navigation={{
            nextEl: '.custom-next-gallery',
            prevEl: '.custom-prev-gallery',
          }}
          loop={false}
          spaceBetween={10}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper2 w-full h-full"
        >
          {images.map((image, idx) => (
            <SwiperSlide key={idx} className="md:rounded-lg overflow-hidden">
              <img
                src={image.image}
                alt={image.description}
                className="select-none h-full w-full object-contain"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="w-full h-[5%] flex justify-between">
        <label className="text-text3">{images[slideIndex]?.description}</label>
        <label className="text-md font-semibold">{`${slideIndex + 1}/${images.length}`}</label>
      </div>
      <div className="w-full h-[10%] md:h-full">
        <Swiper
          onSwiper={setThumbsSwiper}
          loop={false}
          freeMode={true}
          slidesPerView={4}
          spaceBetween={4}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper w-full h-full"
          breakpoints={{
            640: {
              spaceBetween: 4,
              slidesPerView: 6,
            },
            920: {
              spaceBetween: 8,
              slidesPerView: 8,
            },
            1280: {
              spaceBetween: 8,
              slidesPerView: 8,
            },
            1920: {
              spaceBetween: 8,
              slidesPerView: 10,
            },
          }}
        >
          {images.map((image, idx) => (
            <SwiperSlide
              key={idx}
              className={`border-2 ${slideIndex === idx ? 'border-primary' : 'border-transparent'} overflow-hidden rounded-lg`}
            >
              <img
                src={image.image}
                alt={image.description}
                className="h-full w-full object-cover"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </ModalCard>
  );
};

LocationDetailsGalleryModal.propTypes = {
  images: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  defaultIndex: PropTypes.number,
};

export default LocationDetailsGalleryModal;
