import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import { locationFilter } from '../../../../state/location';
import MapLocationListItem from './MapLocationListItem';
import MapLocationListItemLoader from './MapLocationListItemLoader';
import SkeletonBox from '../../../generic/loading/SkeletonBox';
import IconButton from '../../../generic/buttons/IconButton';
import getTagIcon from '../../../../util/getTagIcon';
import tagType from '../../../../config/tagType';

const MapLocationList = ({
  locations,
  locationsQueryResult,
  onSelect,
  isLoading,
  locationTypes,
}) => {
  const [filter, setFilter] = useRecoilState(locationFilter);

  const fetchNextPage = () => {
    setFilter({ ...filter, page: filter.page + 1 });
  };

  const handleLocationClick = (location) => {
    onSelect({
      id: location.id,
      coordinates: [...location.coordinates].reverse(),
    });
  };

  return (
    <div className="size-full">
      {isLoading ? (
        <SkeletonBox className="w-48 h-7 rounded-3xl mb-4" />
      ) : (
        <h2 className="text-lg font-semibold mb-4">{`Visar ${locations.length} av ${locationsQueryResult.count} resultat`}</h2>
      )}
      <ul className="space-y-4 overflow-y-scroll h-full pb-16 bg-accent p-4 rounded-xl">
        {locations?.map((location, idx) => (
          <MapLocationListItem
            key={idx}
            id={location.id}
            image={location.image}
            name={location.name}
            municipality={location.municipality}
            address={location.address}
            onClick={() => handleLocationClick(location)}
            rating={location.average_rating}
            has360={location.has_360}
            locationTypeIcon={getTagIcon(
              locationTypes.find((t) => t.id === location.location_type).name,
              tagType.LOCATION_TYPE,
              '20'
            )}
          />
        ))}
        {isLoading &&
          new Array(filter.pageSize)
            .fill({})
            .map((e, idx) => <MapLocationListItemLoader key={idx} />)}
        {locationsQueryResult.next && (
          <IconButton
            buttonStyle="mx-auto"
            text="Läs in fler platser"
            onClick={fetchNextPage}
          />
        )}
      </ul>
    </div>
  );
};

MapLocationList.propTypes = {
  locations: PropTypes.array.isRequired,
  locationsQueryResult: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default MapLocationList;
