import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { locationFilter } from '../../../state/location';
import { headerState } from '../../../state/filter';
import { view } from '../../../state/view.js';
import useFetchLocationTypes from '../../../hooks/useFetchLocationTypes';
import useFetchCounties from '../../../hooks/useFetchCounties.js';
import useFetchAccessibilityTags from '../../../hooks/useFetchAccessibilityTags';
import closeOnOutsideClick from '../../../util/closeOnOutsideClick';
import device from '../../../config/device.js';
import getDeviceType from '../../../util/getDeviceType';

import MainHeaderMobile from './middle/MainHeaderMobile';
import BottomHeader from './bottom/BottomHeader';
import MainHeader from './middle/desktop/MainHeader';

const Header = () => {
  const navigate = useNavigate();
  const headerRef = useRef();
  const [isHeader, setIsHeader] = useRecoilState(headerState);
  const [filter, setFilter] = useRecoilState(locationFilter);
  const viewState = useRecoilValue(view);

  const { isLoading: isLocationTypesLoading, refetch: fetchLocationTypes } =
    useFetchLocationTypes();
  const { isLoading: isCountiesLoading, refetch: fetchCounties } =
    useFetchCounties();
  const {
    isLoading: isAccessibilityTypesLoading,
    refetch: fetchAccessibilityTags,
  } = useFetchAccessibilityTags();

  const isTypesLoading =
    isLocationTypesLoading || isCountiesLoading || isAccessibilityTypesLoading;

  closeOnOutsideClick(headerRef, setIsHeader);

  const updateFilter = (data) => {
    setFilter(data);
    setIsHeader(false);
    navigate('/');
  };

  const setLocationType = (type) => {
    updateFilter({
      ...filter,
      locationType: type,
      page: 1,
      locationIds: [],
      isFavorites: false,
    });
  };

  const setLocationIds = (ids) => {
    updateFilter({
      ...filter,
      locationIds: ids,
      page: 1,
      locationType: undefined,
      isFavorites: false,
    });
  };

  const setSearchQuery = (input) => {
    updateFilter({ ...filter, query: input, page: 1 });
  };

  const setFavorites = (ids) => {
    updateFilter({
      ...filter,
      page: 1,
      locationIds: ids,
      locationType: '',
      isFavorites: true,
    });
  };

  const onFocus = () => {
    if (viewState === 'map') {
      return;
    }

    window.scrollTo(0, 0);
    setIsHeader(true);
  };

  useEffect(() => {
    fetchLocationTypes();
    fetchCounties();
    fetchAccessibilityTags();

    const deviceType = getDeviceType();

    const scrollHandler = () => {
      const docHeight = document.body.scrollHeight;
      const scrollPercentage =
        window.scrollY / (docHeight - window.innerHeight);
      const hasStartedScrolling = scrollPercentage > 0;

      if (!hasStartedScrolling && deviceType == device.IPHONE) {
        return;
      }

      if (hasStartedScrolling) {
        setIsHeader(false);
      }
    };

    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  return (
    <header
      ref={headerRef}
      className="w-full fixed z-10 bg-accent md:bg-secondary border-b border-border"
    >
      <MainHeader
        isMinimized={!isHeader}
        setLocationIds={setLocationIds}
        isHeader={isHeader}
        onFilterFocus={onFocus}
      />
      <MainHeaderMobile
        setHeader={setIsHeader}
        isHeader={isHeader}
        setLocationIds={setLocationIds}
      />
      <BottomHeader
        isMinimized={!isHeader}
        isTypesLoading={isLocationTypesLoading}
        activeType={filter.locationType}
        setLocationType={setLocationType}
        isFavorites={filter.isFavorites}
        setFavorites={setFavorites}
      />
    </header>
  );
};

export default Header;
