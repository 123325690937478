import React, { useEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import useMutateFetchLocations from '../hooks/useMutateFetchLocations.js';
import useFetchAccessibilityTags from '../hooks/useFetchAccessibilityTags.js';
import useFetchCounties from '../hooks/useFetchCounties.js';
import {
  locationFilter,
  locations,
  locationsQueryResult,
} from '../state/location.js';
import { headerState } from '../state/filter.js';
import { view } from '../state/view.js';
import setTitle from '../util/setTitle.js';
import icon from '../config/icons.js';
import LocationList from '../components/Location/LocationList.js';
import IconButton from '../components/generic/buttons/IconButton.js';
import MapListView from '../components/Maps/MapListView.js';
import disableBackgroundScroll from '../util/disableBackgroundScroll.js';

const LandingPage = () => {
  const [currentView, setCurrentView] = useRecoilState(view);
  const [filter, setFilter] = useRecoilState(locationFilter);
  const {
    isLoading: isLocationsLoading,
    mutate: mutateFetchLocations,
    isSuccess: isFetchLocationsSuccess,
  } = useMutateFetchLocations(
    filter.page,
    filter.pageSize,
    filter.query,
    filter.locationType,
    filter.locationIds
  );
  const { refetch: reFetchAccessibilityTags } = useFetchAccessibilityTags();
  const { refetch: refetchCounties } = useFetchCounties();
  const allLocationsQuery = useRecoilValue(locationsQueryResult);
  const [allLocations, setLocations] = useRecoilState(locations);
  const [isHeader, setHeaderState] = useRecoilState(headerState);
  const isMapView = currentView === 'map';

  const resetLocationsQueryResult = useResetRecoilState(locationsQueryResult);
  const resetLocations = useResetRecoilState(locations);
  const resetFilter = useResetRecoilState(locationFilter);

  disableBackgroundScroll(isMapView);

  const scrollTop = () => {
    // eslint-disable-next-line no-restricted-globals
    scrollTo(0, 1);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const appendNewLocations = () => {
    setLocations((prevData) => [...prevData, ...allLocationsQuery.results]);
  };

  useEffect(() => {
    if (filter.page === 1) {
      resetLocations();
    }
    setHeaderState(false);
    mutateFetchLocations();
  }, [
    filter.locationIds,
    filter.locationType,
    filter.page,
    mutateFetchLocations,
    resetLocations,
    resetLocationsQueryResult,
    setHeaderState,
  ]);

  const toggleMapView = () => {
    scrollTop();
    setCurrentView(currentView === 'map' ? 'list' : 'map');
  };

  useEffect(() => {
    setTitle('Tillgänglighetskartan');

    resetLocationsQueryResult();
    resetFilter();

    resetLocations();
    reFetchAccessibilityTags();
    refetchCounties();
    mutateFetchLocations();
  }, []);

  useEffect(() => {
    if (isFetchLocationsSuccess) {
      appendNewLocations();
    }
  }, [isFetchLocationsSuccess]);

  const fetchNextPage = () => {
    setFilter({ ...filter, page: filter.page + 1 });
  };

  return (
    <div className="relative pb-32 transition-all duration-header pt-[115px] md:pt-[135px]">
      {isMapView ? (
        <MapListView
          locations={allLocations}
          locationsQueryResult={allLocationsQuery}
          isLoading={isLocationsLoading}
        />
      ) : (
        <LocationList
          allLocations={allLocations}
          locationsQueryResult={allLocationsQuery}
          isHeader={isHeader}
          isLoading={isLocationsLoading}
        />
      )}
      <IconButton
        buttonStyle={`
        ${isHeader ? 'opacity-0' : 'opacity-100'}
        ${isMapView ? 'fixed' : 'sticky'}
        bottom-4 md:bottom-16
        transform -translate-x-1/2 left-1/2 z-[6]
        bg-primary text-secondary py-4 px-6 text-md`}
        text={`${isMapView ? 'Visa lista' : 'Visa karta'} `}
        icon={isMapView ? icon.MENU : icon.MAP}
        onClick={toggleMapView}
      />
      {!isLocationsLoading && allLocationsQuery?.next && !isMapView && (
        <IconButton
          buttonStyle={`
            absolute mt-2
          bottom-4 md:bottom-16
          transform -translate-x-1/2 left-1/2 z-[6]
          bg-primary text-secondary 
          py-4 px-6 text-md
          `}
          text="Läs in fler platser"
          onClick={fetchNextPage}
        />
      )}
    </div>
  );
};

export default LandingPage;
