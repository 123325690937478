import { useQuery } from 'react-query';
import { useSetRecoilState } from 'recoil';
import { pages } from '../state/page';
import actions from '../api/pages';

const useFetchPages = () => {
  const setPages = useSetRecoilState(pages);
  return useQuery(
    'useFetchPages',
    async () => {
      const data = await actions.getPages();
      setPages(data);
      return data;
    },
    { enabled: false }
  );
};

export default useFetchPages;
