import { useEffect } from 'react';

// Custom hook to handle clicks outside of the ref element
export default function useCloseOnOutsideClick(ref, func) {
  useEffect(() => {
    function handleClickOutside(event) {
      // Check if the click is outside the ref element
      if (ref.current && !ref.current.contains(event.target)) {
        if (func) {
          func();
        }
      }
    }
    // Add event listener on component mount
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, func]);
}
