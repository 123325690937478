import React from 'react';
import propTypes from 'prop-types';
import getIcon from '../../../util/getIcon';

const IconButton = ({
  text,
  icon,
  buttonStyle,
  iconStyle,
  textStyle,
  onClick,
  hideTextOnMobile = false,
  isDark = true,
  isDisabled,
}) => {
  return (
    <button
      disabled={isDisabled}
      className={`
        ${isDisabled ? 'opacity-50' : ''}
        h-10 flex items-center space-x-2 rounded-full 
        ${isDark ? 'bg-primary text-secondary border-transparent' : 'bg-secondary text-primary border-border'}
        ${isDark ? 'hover:bg-secondary hover:text-primary hover:border-primary' : 'hover:bg-primary hover:text-secondary hover:border-transparent'}
        border 
        transition-all text-md
         ${text ? 'rounded-full p-4' : 'rounded-md p-3'}
         ${buttonStyle}
         `}
      onClick={onClick}
    >
      {getIcon(icon, iconStyle)}
      {text && (
        <p
          className={`whitespace-nowrap ${hideTextOnMobile ? 'hidden md:block' : ''}
        ${textStyle ? textStyle : ''}
        ${icon ? '' : 'w-full'}`}
        >
          {text}
        </p>
      )}
    </button>
  );
};

IconButton.propTypes = {
  text: propTypes.string,
  icon: propTypes.string,
  buttonStyle: propTypes.string,
  iconStyle: propTypes.string,
  textStyle: propTypes.string,
  onClick: propTypes.func,
  hideTextOnMobile: propTypes.bool,
  isDark: propTypes.bool,
};

export default IconButton;
