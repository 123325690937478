import { useMutation } from 'react-query';
import actions from '../api/locations';

const useMutateFetchLocation = () => {
  return useMutation('useMutateFetchLocation', async (id) => {
    const data = await actions.getLocation(id);
    return data;
  });
};

export default useMutateFetchLocation;
