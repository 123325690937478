import React from 'react';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';
import { useRecoilState, useRecoilValue } from 'recoil';
import { pages } from '../../../../state/page';
import getIcon from '../../../../util/getIcon';
import icon from '../../../../config/icons';
import { headerState } from '../../../../state/filter';

const HeaderMenu = ({ isOpen, onClose }) => {
  const allPages = useRecoilValue(pages);
  const [isHeader, setHeader] = useRecoilState(headerState);

  const handleItemClick = () => {
    onClose();
    if (isHeader) {
      setHeader(false);
    }
  };

  return (
    <nav
      className={`
        absolute right-0 top-14 overflow-hidden z-20 flex flex-col select-none bg-secondary
        px-2 ease-in-out
        padding-4 border-border rounded-2xl w-[200%]
        transition-all
        ${isOpen ? 'py-2 border max-h-96' : 'max-h-0'}
      `}
    >
      <div className="flex w-full py-2 px-4 justify-between">
        <button tabIndex={2} onClick={onClose} className="ml-auto">
          {getIcon(icon.CLOSE)}
        </button>
      </div>
      {allPages?.map((page, idx) => (
        <Link
          key={idx}
          onClick={() => handleItemClick()}
          tabIndex={`${isOpen ? idx : -1}`}
          to={`sida/${page.slug}`}
          className="w-full py-2 px-3 hover:bg-hover rounded-lg"
        >
          {page.title}
        </Link>
      ))}
    </nav>
  );
};

HeaderMenu.propTypes = {
  isOpen: propTypes.bool,
  onClose: propTypes.func,
};

export default HeaderMenu;
