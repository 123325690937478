import React from 'react';
import PropTypes from 'prop-types';

const FilterTypePickerButton = ({
  label,
  icon,
  isActive,
  isDisabled,
  onClick,
}) => {
  return (
    <button
      aria-label={label}
      disabled={isDisabled}
      className={`
        ${isDisabled ? 'opacity-30' : ''}
    ${isActive ? 'border-primary' : 'border-transparent'}
    flex flex-col justify-between items-center text-center px-2 py-4
    rounded-lg text-text1 border-2
    hover:bg-accent size-full`}
      onClick={onClick}
    >
      {icon}
      <label className="text-xs font-semibold">{label}</label>
    </button>
  );
};
FilterTypePickerButton.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.any.isRequired,
  isActive: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default FilterTypePickerButton;
