import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MobileFilterSearchWrapper from './MobileFilterSearchWrapper';
import { useRecoilState, useRecoilValue } from 'recoil';
import getTagIcon from '../../../../../util/getTagIcon';
import tagType from '../../../../../config/tagType';
import FilterTypePickerButton from '../../middle/dropdown/FilterTypePickerButton';
import {
  locationTypes,
  options,
  selectedLocationTypes,
} from '../../../../../state/filter';

const MobileFilterSearchLocationType = ({ onSubmit }) => {
  const [selected, setSelected] = useRecoilState(selectedLocationTypes);
  const [search, setSearch] = useState('');
  const allLocationTypes = useRecoilValue(locationTypes);
  const allOptions = useRecoilValue(options);

  const filteredLocationTypes = allLocationTypes
    .filter((t) => t.label.toLowerCase().startsWith(search.toLowerCase()))
    .sort((a, b) => {
      const aInData =
        allOptions.location_types?.find((o) => o.id == a.id)?.count || -1;
      const bInData =
        allOptions.location_types?.find((o) => o.id == b.id)?.count || -1;
      return bInData - aInData;
    });

  const onSelect = (id) => {
    setSelected((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  return (
    <MobileFilterSearchWrapper
      searchPlaceholder="Sök på typ av plats..."
      search={search}
      setSearch={setSearch}
      onSubmit={() => onSubmit(selected)}
    >
      <div className="overflow-y-scroll max-h-full dropdown-grid w-full">
        {filteredLocationTypes.map((type, idx) => {
          const locationType = allOptions.location_types?.find(
            (e) => e.id == type.id
          );
          return (
            <FilterTypePickerButton
              label={`${type.label} ${locationType ? `(${locationType.count})` : ''}`}
              isDisabled={!locationType}
              key={idx}
              onClick={() => onSelect(type.id)}
              isActive={selected.includes(type.id)}
              icon={getTagIcon(type.name, tagType.LOCATION_TYPE)}
            />
          );
        })}
      </div>
    </MobileFilterSearchWrapper>
  );
};

MobileFilterSearchLocationType.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default MobileFilterSearchLocationType;
