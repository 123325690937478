import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MobileFilterSearchWrapper from './MobileFilterSearchWrapper';
import {
  accessibilityTags,
  options,
  selectedAccessibilityTypes,
} from '../../../../../state/filter';
import { useRecoilState, useRecoilValue } from 'recoil';
import getTagIcon from '../../../../../util/getTagIcon';
import tagType from '../../../../../config/tagType';
import FilterTypePickerButton from '../../middle/dropdown/FilterTypePickerButton';

const MobileFilterSearchAccessibilityTypes = ({ onSubmit }) => {
  const currentOptions = useRecoilValue(options);
  const allOptions = useRecoilValue(accessibilityTags);
  const [selected, setSelected] = useRecoilState(selectedAccessibilityTypes);
  const [search, setSearch] = useState('');

  const filteredAccessibilityCategories = allOptions
    .filter((t) => t.label.toLowerCase().startsWith(search.toLowerCase()))
    .sort((a, b) => {
      const aInData =
        currentOptions.accessibility_tags?.find((o) => o.id == a.id)?.count ||
        -1;
      const bInData =
        currentOptions.accessibility_tags?.find((o) => o.id == b.id)?.count ||
        -1;
      return bInData - aInData;
    });

  const onSelect = (id) => {
    setSelected((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  return (
    <MobileFilterSearchWrapper
      searchPlaceholder="Sök på tillgänglighet..."
      search={search}
      setSearch={setSearch}
      onSubmit={() => onSubmit(selected)}
    >
      <div className="overflow-y-scroll size-full space-y-2">
        {filteredAccessibilityCategories.map((categories, idx) => (
          <div key={idx} className="border-b border-border">
            <label className="text-text2">{categories.label}:</label>
            <div className="size-full dropdown-grid">
              {categories.tags.map((type, idx) => {
                const option = currentOptions.accessibility_tags?.find(
                  (o) => o.id == type.id
                );
                return (
                  <FilterTypePickerButton
                    key={idx}
                    label={`${type.label} ${option ? `(${option.count})` : ''}`}
                    isDisabled={!option}
                    id={type.id}
                    onClick={() => onSelect(type.id)}
                    isActive={selected.includes(type.id)}
                    icon={getTagIcon(type.name, tagType.ACCESSIBILITY)}
                  />
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </MobileFilterSearchWrapper>
  );
};

MobileFilterSearchAccessibilityTypes.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default MobileFilterSearchAccessibilityTypes;
