/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import useMutateFetchLocation from '../hooks/useMutateFetchLocation.js';
import useFetchAccessibilityTags from '../hooks/useFetchAccessibilityTags.js';
import icon from '../config/icons.js';
import setTitle from '../util/setTitle.js';
import getIcon from '../util/getIcon.js';
import disableBackgroundScroll from '../util/disableBackgroundScroll.js';
import { accessibilityTags } from '../state/filter.js';

import LocationDetailsPlaceDescription from '../components/LocationDetailsPage/LocationDetailsPlaceDescription.js';
import LocationDetailsTagsList from '../components/LocationDetailsPage/LocationDetailsTagsList.js';
import LocationDetailsUpperSection from '../components/LocationDetailsPage/LocationDetailsUpperSection.js';
import LocationDetailsMap from '../components/LocationDetailsPage/LocationDetailsMap.js';
import IconButton from '../components/generic/buttons/IconButton.js';
import LocationDetailsGallery from '../components/LocationDetailsPage/LocationDetailsGallery/LocationDetailsGallery.js';
import LocationDetailsGalleryModal from '../components/LocationDetailsPage/LocationDetailsGallery/LocationDetailsGalleryModal.js';
import LocationDetailsMapViewSwitcher from '../components/LocationDetailsPage/LocationDetailsMapViewSwitcher.js';
import IconLinkButton from '../components/generic/buttons/IconLinkButton.js';
import LocationDetailsLoader from '../components/LocationDetailsPage/LocationDetailsLoader.js';
import ShareModal from '../components/LocationDetailsPage/ShareModal.js';
import LocationDetailsRichTextMenu from '../components/LocationDetailsPage/LocationDetailsRichTextMenu.js';
import ReportModal from '../components/LocationDetailsPage/ReportModal.js';
import ReviewList from '../components/LocationDetailsPage/reviews/ReviewList.js';
import ReviewSummaryCard from '../components/LocationDetailsPage/reviews/ReviewSummaryCard.js';
import ReviewModal from '../components/LocationDetailsPage/reviews/ReviewModal.js';
import LocationDetails360Modal from '../components/LocationDetailsPage/LocationDetails360Modal.js';

const LocationDetailPage = () => {
  const param = useParams();
  const [isMapView, setMapView] = useState(false);
  const [is360View, set360View] = useState(false);
  const [isShareModal, setShareModal] = useState(false);
  const [isReportModal, setReportModal] = useState(false);
  const [isReviewModal, setReviewModal] = useState(false);
  const { isLoading, data: location, mutate } = useMutateFetchLocation();
  const {
    isLoading: isAccessibilityTagsLoading,
    refetch: reFetchAccessibilityTags,
  } = useFetchAccessibilityTags();
  const [galleryModalIndex, setGalleryModalIndex] = useState(-1);
  const allAccessibilityTags = useRecoilValue(accessibilityTags);
  const allTags = allAccessibilityTags.flatMap((cat) => cat.tags);

  const getTags = (ids) => {
    return allTags.filter((tag) => ids.includes(tag.id));
  };

  disableBackgroundScroll(
    isReviewModal ||
      isReportModal ||
      isShareModal ||
      isMapView ||
      is360View ||
      galleryModalIndex !== -1
  );

  useEffect(() => {
    mutate(param.id);
    reFetchAccessibilityTags();
    setTitle('Tillgänglighetskartan');
    window.scrollTo(0, 0);
  }, [param.id]);

  return (
    <div className="relative w-full mt-[130px] md:mt-[160px] mb-16 md:w-[80vw] max-w-[1620px] flex px-sm md:px-md lg:px-lg mx-auto">
      {galleryModalIndex == -1 && (
        <LocationDetailsMapViewSwitcher setMap={setMapView} isMap={isMapView} />
      )}
      <ShareModal
        onClose={() => setShareModal(false)}
        isOpen={isShareModal}
        location={location}
      />
      <ReportModal
        onClose={() => setReportModal(false)}
        isOpen={isReportModal}
        locationId={location?.id}
      />
      <ReviewModal
        onClose={() => setReviewModal(false)}
        isOpen={isReviewModal}
        locationId={location?.id}
      />
      <LocationDetails360Modal
        image={location?.image3D}
        isOpen={is360View}
        setOpen={set360View}
      />
      {isLoading || isAccessibilityTagsLoading || !location ? (
        <LocationDetailsLoader />
      ) : (
        <>
          {isMapView && (
            <div className="fixed w-full h-full z-10 top-0 left-0">
              <LocationDetailsMap location={location} />
            </div>
          )}
          <LocationDetailsGalleryModal
            images={location.images}
            onClose={() => setGalleryModalIndex(-1)}
            isOpen={galleryModalIndex !== -1}
            defaultIndex={galleryModalIndex}
          />
          <div className="flex flex-col w-full">
            <LocationDetailsUpperSection
              locationId={location?.id}
              title={location.subheader}
              handleShare={() => setShareModal(true)}
            />
            <div className="flex md:mb-6 h-[300px] md:h-[500px] xl:h-[600px] relative">
              <div className="w-full md:w-1/2 relative mr-3">
                <LocationDetailsGallery
                  images={location.images}
                  mainImage={location.image}
                  onImageClick={(imageIndex) =>
                    setGalleryModalIndex(imageIndex)
                  }
                />
                {location.images.length > 0 && (
                  <IconButton
                    buttonStyle="invisible md:visible absolute left-1/2 md:left-auto transform -translate-x-1/2 md:translate-x-0 md:right-2 bottom-2 md:bottom-5"
                    text="Visa alla bilder"
                    icon={icon.IMAGE}
                    onClick={() => setGalleryModalIndex(0)}
                    isDark={false}
                  />
                )}
              </div>
              <div className="overflow-hidden rounded-2xl h-full w-1/2 hidden md:block relative animate-fadeIn">
                <LocationDetailsMap location={location} />
              </div>
              {location.image3D && (
                <IconButton
                  buttonStyle="absolute top-2 left-2 z-10"
                  text="Visa i 360"
                  icon={icon._360}
                  onClick={() => set360View(true)}
                  isDark={false}
                />
              )}
            </div>
            <div className="flex justify-between">
              <div className="flex flex-col gap-2">
                <h3 className="text-3xl font-medium">{location.name}</h3>
                <div className="flex items-center gap-2 text-text3">
                  {getIcon(icon.PIN)}
                  <p className="text-sm">{location.address}</p>
                </div>
              </div>
              <IconLinkButton
                text="Hitta hit"
                iconStyle="text-2xl"
                buttonStyle={'md:flex px-4'}
                icon={icon.DIRECTIONS}
                href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(location.name + ' ' + location.address)}`}
                isDark={false}
              />
            </div>
            <div className="flex flex-col md:flex-row gap-y-6 md:gap-y-0 gap-x-8 mt-6 mb-8">
              <div className="w-full md:w-1/2 space-y-8 gap-2 mb-8">
                <LocationDetailsPlaceDescription
                  title={'Beskrivning'}
                  description={location.description}
                />
                {location.rich_descriptions?.length > 0 && (
                  <LocationDetailsRichTextMenu
                    richTextItems={location.rich_descriptions}
                  />
                )}
              </div>
              {location.accessibility_tags.length > 0 && (
                <div className="w-full md:w-1/2 space-y-20 md:space-y-8">
                  <LocationDetailsTagsList
                    accessibilityTags={getTags(location.accessibility_tags)}
                  />
                  <button
                    className="items-center mx-auto flex space-x-2 underline md:pt-20"
                    onClick={() => setReportModal(true)}
                  >
                    {getIcon(icon.FLAG)}
                    <p>Rapportera denna platsen</p>
                  </button>
                </div>
              )}
            </div>
            <div className="flex flex-col gap-y-6 md:gap-y-0 gap-x-8 mt-6">
              <h3 className="text-2xl font-medium mb-6">Omdömen</h3>
            </div>
            <div className="flex flex-col md:flex-row md:space-x-16 mb-20">
              <div className="w-full md:w-1/2 space-y-8">
                {location.reviews?.length > 0 ? (
                  <ReviewList reviews={location.reviews} />
                ) : (
                  <p className="text-text3">
                    Den här platsen har inga omdömen.
                  </p>
                )}
              </div>
              <div className="w-full md:w-1/2 space-y-8 mt-20 md:mt-0">
                {location.reviews.length > 0 && (
                  <ReviewSummaryCard
                    score={parseInt(location.average_rating)}
                    reviews={location.reviews}
                  />
                )}
                <div className="md:w-fit md:space-x-4 pace-x-0 border border-border space-y-4 md:space-y-0 rounded-xl flex-col md:flex-row flex p-5 justify-between items-center">
                  <label className="text-md text-text1">
                    Har du varit här?
                  </label>
                  <IconButton
                    text="Lämna ett omdöme"
                    onClick={() => setReviewModal(true)}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default LocationDetailPage;
