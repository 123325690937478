import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { locationTypes } from '../../state/filter';
import useMutateFetchLocation from '../../hooks/useMutateFetchLocation';
import MapModal from './MapModal/MapModal';
import MapBar from './MapBar';
import MapComponent from './MapComponent';
import Image360Viewer from '../generic/Image360Viewer';
import Loader from '../generic/loading/Loader';

const MapListView = ({ locations, locationsQueryResult, isLoading }) => {
  const ref360 = useRef();
  const mapRef = useRef();
  const navigate = useNavigate();
  const [is360View, set360View] = useState(false);
  const allLocationTypes = useRecoilValue(locationTypes);
  const [focusLocation, setFocusLocation] = useState(null);
  const initialViewState = {
    width: '100%',
    height: '100%',
    latitude: 55.7815,
    longitude: 12.7562,
    zoom: 7,
    bearing: 0,
    pitch: 0,
  };

  const [viewPort, setViewPort] = useState({ ...initialViewState });

  const {
    mutate: mutateFetchLocation,
    data: locationData,
    isLoading: isSingleLocationLoading,
  } = useMutateFetchLocation();

  const fetchLocation = useCallback(
    (locationId) => {
      mutateFetchLocation(locationId);
    },
    [mutateFetchLocation]
  );

  const toggle360View = useCallback(() => {
    set360View((prev) => !prev);
  }, []);

  const handleLocationClick = useCallback(
    (locationData) => {
      setFocusLocation((prev) => {
        if (prev?.id === locationData?.id) {
          navigate(`platser/${prev.id}`);
        }
        return locationData;
      });
    },
    [fetchLocation]
  );

  const handleMapClick = useCallback((event) => {
    if (event.target.className === 'mapboxgl-canvas') {
      setFocusLocation(null);
    }
  }, []);

  const isViewUpdated = () => {
    const isChanged =
      parseFloat(viewPort.latitude.toFixed(4)) !== initialViewState.latitude ||
      parseFloat(viewPort.longitude.toFixed(4)) !==
        initialViewState.longitude ||
      viewPort.zoom !== initialViewState.zoom ||
      viewPort.bearing !== initialViewState.bearing ||
      viewPort.pitch !== initialViewState.pitch;
    return isChanged;
  };

  const resetNavigation = () => {
    setFocusLocation(null);
    setViewPort(initialViewState);
    mapRef.current?.flyTo({
      center: [initialViewState.longitude, initialViewState.latitude],
      zoom: initialViewState.zoom,
      bearing: initialViewState.bearing,
      pitch: initialViewState.pitch,
      speed: 1.5,
      curve: 1.5,
      essential: true,
    });
  };

  const handleZoom = (isZoom) => {
    if (is360View) {
      if (ref360.current) {
        const currentZoom = ref360.current.getZoomLevel();
        ref360.current.animate({
          zoom: currentZoom + (isZoom ? 20 : -20),
          speed: '100rpm',
        });
      }
    } else {
      if (isZoom) {
        mapRef.current?.zoomIn();
      } else {
        mapRef.current?.zoomOut();
      }
    }
  };

  useEffect(() => {
    if (!focusLocation) {
      set360View(false);
    }
    if (focusLocation?.id) {
      fetchLocation(focusLocation.id);
    }
  }, [focusLocation]);

  return (
    <div
      onClick={handleMapClick}
      className="flex h-screen w-screen fixed z-[5] bg-secondary"
    >
      {isLoading && <Loader className="top-8 md:top-12 size-12" />}
      <MapModal
        locations={locations}
        locationsQueryResult={locationsQueryResult}
        isLocationsLoading={isLoading}
        onLocationClick={handleLocationClick}
        focusLocation={focusLocation}
        locationData={locationData}
        is360View={is360View}
        isSingleLocationLoading={isSingleLocationLoading}
      />
      {focusLocation && (
        <Image360Viewer
          src={locationData?.image3D}
          ref360={ref360}
          isActive={is360View}
        />
      )}
      <MapComponent
        focusLocation={focusLocation}
        locations={locations}
        allLocationTypes={allLocationTypes}
        onMarkerClick={handleLocationClick}
        mapRef={mapRef}
        viewPort={viewPort}
        setViewPort={setViewPort}
        isLoading={isLoading}
      />
      <MapBar
        focusLocation={focusLocation}
        locationData={locationData}
        toggle360View={toggle360View}
        is360View={is360View}
        handleZoom={handleZoom}
        resetNavigation={resetNavigation}
        bearing={viewPort.bearing}
        isViewChanged={isViewUpdated()}
      />
    </div>
  );
};

MapListView.propTypes = {
  locations: PropTypes.array.isRequired,
  locationsQueryResult: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default MapListView;
