import React, { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import useFetchPages from '../../hooks/useFetchPages';
import { isOverlay } from '../../state/view';
import { favoritesLocationIds } from '../../state/location';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { getSavedLocations } from '../../util/savedLocations';

import LandingPage from '../../pages/LandingPage';
import LocationDetailPage from '../../pages/LocationDetailPage';
import Footer from './Footer';
import Header from './header/Header';
import GenericPage from '../../pages/GenericPage';
import Overlay from './Overlay';
import CookiesModal from './CookiesModal';
import RWDDebugger from './RWDDebugger';

const AppSwitcher = () => {
  const overlay = useRecoilValue(isOverlay);
  const { refetch: refetchPages } = useFetchPages();
  const setFavorites = useSetRecoilState(favoritesLocationIds);

  useEffect(() => {
    refetchPages();
    setFavorites(getSavedLocations());
  }, []);

  return (
    <div className="flex flex-col min-h-screen">
      <Overlay isActive={overlay} />
      <RWDDebugger />
      <Toaster />
      <Header />
      <CookiesModal />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/platser/:id" element={<LocationDetailPage />} />
        <Route path="/sida/:pageSlug" element={<GenericPage />} />
        <Route path="*" element={<Navigate to="/sida/404" replace />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default AppSwitcher;
