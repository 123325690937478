import React, { useRef, useState } from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import closeOnOutsideClick from '../../../../util/closeOnOutsideClick';
import HeaderMenuButton from './HeaderMenuButton';
import HeaderMenu from './HeaderMenu';
import icon from '../../../../assets/iconBeta.svg';
import UserWayToggle from './UserWayToggle';

const TopHeader = ({ isVisible }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  closeOnOutsideClick(menuRef, setMenuOpen);

  return (
    <div
      className={`hidden sm:flex
      absolute left-0 top-2 w-full
      items-center justify-between
      px-sm md:px-md lg:px-lg
       transition-all duration-header`}
    >
      <div className="flex flex-col items-start z-30">
        <Link className="z-30" to="/">
          <img alt="logotyp" className="sm:w-40 md:w-48 lg:w-full" src={icon} />
        </Link>
        <div className="z-30">
          <UserWayToggle />
        </div>
      </div>
      <div ref={menuRef} className="relative z-30">
        <HeaderMenuButton onClick={() => setMenuOpen(!isMenuOpen)} />
        <HeaderMenu isOpen={isMenuOpen} onClose={() => setMenuOpen(false)} />
      </div>
    </div>
  );
};

TopHeader.propTypes = {
  isVisible: propTypes.bool,
};

export default TopHeader;
