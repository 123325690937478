import React from 'react';
import getIcon from '../../util/getIcon';
import icon from '../../config/icons';

const Rating = ({ rating, reviews = 23 }) => {
  return (
    <div className="rating flex items-center">
      {getIcon(icon.STAR)}
      <label className="ml-2">
        {`${rating}/5`}
        <span className="text-text4 hidden">{` (${reviews})`}</span>
      </label>
    </div>
  );
};

export default Rating;
