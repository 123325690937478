import React from 'react';
import propTypes from 'prop-types';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { accessibilityTags, locationTypes } from '../../state/filter';
import {
  favoritesLocationIds,
  locationFilter,
  locations,
} from '../../state/location';
import { removeSavedLocation, saveLocation } from '../../util/savedLocations';
import LocationListItem from './LocationListItem';
import LocationItemListLoader from './LocationListItemLoader';
import toast from 'react-hot-toast';

const LocationList = ({ allLocations, isLoading }) => {
  const allLocationTypes = useRecoilValue(locationTypes);
  const allAccessibilityTags = useRecoilValue(accessibilityTags);
  const allTags = allAccessibilityTags.flatMap((cat) => cat.tags);

  const [favorites, setFavorites] = useRecoilState(favoritesLocationIds);
  const filter = useRecoilValue(locationFilter);
  const setLocations = useSetRecoilState(locations);

  const getTags = (ids) => {
    return allTags.filter((tag) => ids.includes(tag.id));
  };

  const handleFavorite = (locationId) => {
    const isSaved = favorites.includes(locationId);

    const icon = isSaved ? '✖️' : '🖤️';
    const message = isSaved
      ? 'Platsen har tagits bort från dina favoriter.'
      : 'Platsen har lagts till bland dina favoriter!';

    if (isSaved) {
      setFavorites(favorites.filter((id) => id !== locationId));
      removeSavedLocation(locationId);
    } else {
      setFavorites([...favorites, locationId]);
      saveLocation(locationId);
    }

    toast(message, {
      icon: icon,
      position: 'top-center',
      style: {
        color: '#1E1E1E',
        background: '#FEFEFE',
        border: '1px solid #1E1E1E',
        padding: '16px',
      },
    });

    if (isSaved && filter.isFavorites) {
      setLocations(
        allLocations.filter((location) => location.id !== locationId)
      );
    }
  };

  return (
    <div className="relative location-list-grid px-sm md:px-md lg:px-lg pt-8">
      {allLocations?.map((location) => (
        <LocationListItem
          key={location.id}
          loadIndex={location.id % filter.pageSize}
          id={location.id}
          name={location.name}
          address={location.address}
          municipality={location.municipality}
          locationType={
            allLocationTypes?.find((e) => e.id === location.location_type)?.name
          }
          image={location.image}
          accessibilityTags={getTags(location.accessibility_tags)}
          isFavorite={favorites.includes(location.id)}
          setFavorite={() => handleFavorite(location.id)}
        />
      ))}
      {isLoading &&
        Array(filter.pageSize)
          .fill(null)
          .map((_, idx) => <LocationItemListLoader key={idx} />)}
    </div>
  );
};

LocationList.propTypes = {
  allLocations: propTypes.array,
  isLoading: propTypes.bool,
};

export default LocationList;
