import React from 'react';
import PropTypes from 'prop-types';

const LocationDetailsGalleryImageButton = ({
  url,
  description,
  onClick,
  style,
  height = 'h-full',
  width = 'w-full',
}) => {
  return (
    <button
      className={`${style} ${width} ${height} hover:opacity-90 hover:scale-[1.02] transition-all ease-in-out`}
      onClick={onClick}
    >
      <img
        src={url}
        alt={description}
        className="w-full h-full object-cover rounded-2xl animate-fadeIn"
      />
    </button>
  );
};

LocationDetailsGalleryImageButton.propTypes = {
  url: PropTypes.string.isRequired,
  description: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default LocationDetailsGalleryImageButton;
