import React from 'react';
import ReviewStars from './ReviewStars';
import { formatDistanceToNow } from 'date-fns';
import { sv } from 'date-fns/locale';

const ReviewCard = ({ name, timestamp, rating, description, image }) => {
  const getTimeAgoInSwedish = (timestamp) => {
    return formatDistanceToNow(new Date(timestamp), {
      addSuffix: true,
      locale: sv,
    });
  };

  return (
    <div className="review-card border px-6 py-4 rounded-xl bg-white">
      <div className="flex justify-between mb-2">
        <div className="flex flex-col">
          <h3 className="text-lg text-text1 leading-6">{name}</h3>
          <label className="text-xs text-text2">
            {getTimeAgoInSwedish(timestamp)}
          </label>
        </div>
        <div className="ml-4">
          <ReviewStars rating={rating} />
        </div>
      </div>
      <p className="mb-2 text-text3 text-sm">{description}</p>
      {image && (
        <div className="mb-2">
          <img src={image} alt="Review" className="w-full h-auto rounded-md" />
        </div>
      )}
    </div>
  );
};

export default ReviewCard;
