import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react';
import propTypes from 'prop-types';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import useMutateFetchOptions from '../../../../../hooks/useMutateFetchOptions';
import {
  accessibilityTags,
  counties,
  locationTypes,
  options,
  overlayState,
} from '../../../../../state/filter';
import generateFilterText from '../../../../../util/generateFilterText';
import FilterItem from './FilterItem';
import FilterSearchButton from '../../../buttons/FilterSearchButton';
import FilterDropdownWrapper from '../dropdown/FilterDropdownWrapper';
import RegionPicker from '../dropdown/RegionPicker';
import AccessibilityPicker from '../dropdown/AccessibilityPicker';
import LocationTypePicker from '../dropdown/LocationTypePicker';
import Loader from '../../../loading/Loader';
import closeOnOutsideClickFunc from '../../../../../util/closeOnOutsideClickFunc';

const MainFilter = ({
  isMinimized,
  active,
  setActive,
  isHeader,
  setLocationIds,
  onFilterFocus,
}) => {
  const [outputOptions, setOutputOptions] = useRecoilState(options);
  const [inputOptions, setInputOptions] = useState({});
  const filterRef = useRef(null);
  const setOverlay = useSetRecoilState(overlayState);
  const {
    mutate: mutateFetchOptions,
    isSuccess: isOptionsSuccess,
    data: optionsData,
    isLoading,
  } = useMutateFetchOptions(inputOptions);

  const allCounties = useRecoilValue(counties);
  const allLocationTypes = useRecoilValue(locationTypes);
  const allAccessibilityTags = useRecoilValue(accessibilityTags);

  const onClose = () => {
    setActive(-1);
  };

  closeOnOutsideClickFunc(filterRef, onClose);

  const onSearch = useCallback(() => {
    setLocationIds(outputOptions.location_ids);
    setActive(-1);
  }, [setLocationIds, outputOptions.location_ids, setActive]);

  const onSubmit = useCallback(
    (key, data) => {
      if (data !== null) {
        setActive((prevActive) =>
          prevActive === items.length ? -1 : prevActive + 1
        );
      }

      setInputOptions((prevState) => {
        const newState = { ...prevState };
        if (!data || data.length === 0) {
          delete newState[key];
        } else {
          newState[key] = data;
        }
        return newState;
      });
    },
    [setActive]
  );

  const getRegionText = () =>
    generateFilterText(
      allCounties.flatMap((county) => county.municipalities),
      inputOptions.municipality_ids,
      'Hela sverige'
    );

  const getLocationTypeText = () =>
    generateFilterText(
      allLocationTypes,
      inputOptions.location_type_ids,
      'Vad vill du söka efter?'
    );

  const getAccessibilityText = () =>
    generateFilterText(
      allAccessibilityTags,
      inputOptions.accessibility_tag_ids,
      'Välj utifrån tillgänglighet'
    );

  useEffect(() => {
    mutateFetchOptions();
  }, [inputOptions, mutateFetchOptions]);

  useEffect(() => {
    if (isOptionsSuccess) {
      setOutputOptions(optionsData);
    }
  }, [isOptionsSuccess, optionsData, setOutputOptions]);

  useEffect(() => {
    if (active === -1 && Object.keys(inputOptions).length > 0) {
      onSearch();
    }
  }, [outputOptions]);

  useEffect(() => {
    setOverlay(active !== -1);
  }, [active, setOverlay]);

  useEffect(() => {
    if (!isHeader) {
      setActive(-1);
    }
  }, [isHeader, setActive]);

  const items = useMemo(
    () => [
      {
        id: 1,
        title: 'Välj län',
        subTitle: getRegionText(),
        minimizedValue: 'Region',
        dropdown: (
          <RegionPicker
            availableOptions={outputOptions}
            onSubmit={(val) => onSubmit('municipality_ids', val)}
          />
        ),
      },
      {
        id: 2,
        title: 'Välj typ av plats',
        subTitle: getLocationTypeText(),
        minimizedValue: 'Plats',
        dropdown: (
          <LocationTypePicker
            availableOptions={outputOptions.location_types}
            onSubmit={(val) => onSubmit('location_type_ids', val)}
          />
        ),
      },
      {
        id: 3,
        title: 'Tillgänglighet',
        subTitle: getAccessibilityText(),
        minimizedValue: 'Tillgänglighet',
        dropdown: (
          <AccessibilityPicker
            availableOptions={outputOptions.accessibility_tags}
            onSubmit={(val) => onSubmit('accessibility_tag_ids', val)}
          />
        ),
      },
    ],
    [
      outputOptions,
      getRegionText,
      getLocationTypeText,
      getAccessibilityText,
      onSubmit,
    ]
  );

  return (
    <div
      className={`
        relative border border-border rounded-filter shadow
        transition-all duration-header ease-filter
        ${isMinimized ? 'h-[46px] sm:w-1/2 lg:w-1/4' : 'sm:h-[50px] md:h-[70px] lg:h-[94px] w-[90vw] lg:w-[60vw] mt-48'}
        ${active !== -1 ? ' bg-hover' : 'bg-secondary'}
      `}
      ref={filterRef}
      onClick={onFilterFocus}
    >
      {isLoading && (
        <div className="absolute -left-10 transform -translate-y-1/2 top-1/2">
          <Loader />
        </div>
      )}
      <div className="flex justify-between h-full w-full rounded-full">
        {items.map((item, idx) => (
          <div className="flex w-full" key={item.id}>
            <FilterItem
              id={item.id}
              title={item.title}
              subTitle={item.subTitle}
              minimizedValue={item.minimizedValue}
              setActive={() => setActive(active === item.id ? -1 : item.id)}
              activeItem={active}
              isMinimized={isMinimized}
            />
            {active === -1 && (
              <div className="transition-all border h-2/5 group-hover:border-none my-auto" />
            )}
            <FilterDropdownWrapper
              isOpen={active === item.id}
              extraStyle={`
                ${isHeader ? 'w-1/2 top-16 md:top-24 lg:top-28' : 'top-16 w-5/6'}
                 ${idx === 0 ? 'left-0' : idx === items.length - 1 ? 'right-0' : 'left-1/2 transform -translate-x-1/2'}
              `}
            >
              {item.dropdown}
            </FilterDropdownWrapper>
          </div>
        ))}
        <div
          className={`flex items-center justify-center overflow-hidden
            transition-all overflow-hidden duration-filter
            ${isMinimized ? 'w-1/2 p-2' : 'w-2/3 h-full p-1'}`}
        >
          <FilterSearchButton
            isMinimized={isMinimized}
            onClick={onSearch}
            text={`Visa ${outputOptions.location_ids.length} platser`}
          />
        </div>
      </div>
    </div>
  );
};

MainFilter.propTypes = {
  isMinimized: propTypes.bool,
  active: propTypes.number.isRequired,
  setActive: propTypes.func.isRequired,
  isHeader: propTypes.bool.isRequired,
  setLocationIds: propTypes.func.isRequired,
  onFilterFocus: propTypes.func.isRequired,
};

export default MainFilter;
