import React from 'react';
import PropTypes from 'prop-types';
import { ReactPhotoSphereViewer } from 'react-photo-sphere-viewer';

const Image360Viewer = ({ src, ref360, isActive, className }) => {
  return (
    <div
      className={`h-full w-full absolute top-0
         transform -translate-x-1/2 left-1/2 overflow-hidden
         ${className}
         z-[1] ${isActive ? '' : 'hidden'}`}
    >
      <ReactPhotoSphereViewer
        ref={ref360}
        src={src}
        width="100%"
        height="100%"
        navbar={['zoom', 'move', 'fullscreen']}
        defaultYaw={Math.PI}
        defaultPitch={0}
        defaultZoomLvl={0}
        autoplay
        fisheye={true}
        maxPov={0}
        autorotateSpeed="2rpm"
        panorama360
      />
    </div>
  );
};

Image360Viewer.propTypes = {
  src: PropTypes.string,
  ref360: PropTypes.any,
  isActive: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

export default Image360Viewer;
