import React from 'react';
import propTypes from 'prop-types';

import icon from '../../../../../assets/icons/icon.svg';

const HeaderTitle = ({ isMinimized }) => {
  return (
    <div
      className={`flex flex-col items-center 
      absolute left-1/2 transform -translate-x-1/2 
                w-fit space-y-5 overflow-hidden
                    transition-all duration-500 ease-filter
                     ${isMinimized ? 'scale-0 animate-fadeOut -top-52' : 'scale-100 animate-fadeIn top-0'}
                `}
    >
      <img alt="logotyp" className="size-14" src={icon} />
      <div>
        <h1 className="text-text1 text-2xl font-medium">
          Välkommen till tillgänglighetskartan
        </h1>
        <p className="text-text2 whitespace-nowrap">
          Sök och se tillgängligheter på platser runt om i Sverige.
        </p>
      </div>
    </div>
  );
};

HeaderTitle.propTypes = {
  isMinimized: propTypes.bool,
};

export default HeaderTitle;
