import React from 'react';
import propTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import { activeDropdown } from '../../../../../state/filter';
import MainFilter from './MainFilter';
import TopHeader from '../../top/TopHeader';
import HeaderTitle from './HeaderTitle';

const MainHeader = ({
  isMinimized,
  setLocationIds,
  isHeader,
  onFilterFocus,
}) => {
  const [activeFilter, setActiveFilter] = useRecoilState(activeDropdown);
  return (
    <div
      className={`hidden sm:flex flex-col relative bg-inherit
            transition-all duration-header
            ${isMinimized ? 'h-16' : 'h-[65vh]'}`}
    >
      <TopHeader />
      <div
        className={`absolute relative w-full flex justify-center top-1/2 transform 
        transition-transform duration-filter
        ${activeFilter == -1 || !isHeader ? '-translate-y-1/2' : 'translate-y-[-140%]'}
         z-20`}
      >
        <HeaderTitle isMinimized={!isHeader || activeFilter !== -1} />
        <MainFilter
          isMinimized={isMinimized}
          active={activeFilter}
          setActive={setActiveFilter}
          isHeader={isHeader}
          setLocationIds={setLocationIds}
          onFilterFocus={onFilterFocus}
        />
      </div>
    </div>
  );
};

MainHeader.propTypes = {
  isMinimized: propTypes.bool,
  setLocationIds: propTypes.func,
  isHeader: propTypes.bool,
  onFilterFocus: propTypes.func,
};

export default MainHeader;
