import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import propTypes from 'prop-types';
import { Navigation, FreeMode } from 'swiper/modules';
import { useRecoilValue } from 'recoil';
import { locationTypes } from '../../../../state/filter';
import { overlayState } from '../../../../state/filter';
import LocationTypeButton from './LocationTypeButton';
import ArrowButton from '../../buttons/ArrowButton';
import { favoritesLocationIds } from '../../../../state/location';

import 'swiper/css';
import 'swiper/css/pagination';

const BottomHeader = ({
  isMinimized,
  activeType,
  setLocationType,
  isTypesLoading,
  isFavorites,
  setFavorites,
}) => {
  const [pageState, setPageState] = useState(1);
  const allLocationTypes = useRecoilValue(locationTypes);
  const isOverlay = useRecoilValue(overlayState);
  const favorites = useRecoilValue(favoritesLocationIds);

  return (
    <div
      className={`flex flex-col overflow-hidden bg-inherit
       transition-all duration-header shadow-md group/bottomheader
       ${isMinimized ? 'h-[50px] md:h-[70px]' : 'h-[50px] md:h-[70px]'} `}
    >
      <div
        className={`absolute h-screen w-screen
         bg-gradient-to-t from-70% from-black z-10
         transition-all duration-filter
         ease-[cubic-bezier(0.1,0.9,0.2,.1)]
         pointer-events-none
         ${isOverlay ? 'opacity-30' : 'opacity-0'}`}
      />
      <h2
        className={`text-sm lg:text-xl font-semibold bottom-24 left-16 absolute hidden md:block text-text1
            transition-all duration-header ${isMinimized ? 'opacity-0 z-0' : 'opacity-100 z-10'}`}
      >
        Upptäck alla kategorier
      </h2>
      <div className="px-4 md:px-16 flex flex-col relative h-full justify-end md:justify-center">
        <div
          className={`hidden md:flex space-x-4 top-0 absolute right-0 w-full justify-between
            top-1/2 transform -translate-y-1/2 px-4
            transition-all duration-500 opacity-0 group-hover/bottomheader:opacity-100`}
        >
          <ArrowButton
            style={`custom-prev ${pageState === 1 ? 'opacity-0' : ''}`}
            orientation="-rotate-90"
          />
          <ArrowButton
            style={`custom-next ${pageState === -1 ? 'opacity-0' : ''}`}
            orientation="rotate-90"
          />
        </div>
        <Swiper
          onSlideChange={(e) =>
            setPageState(e.isBeginning ? 1 : e.isEnd ? -1 : 0)
          }
          slidesPerView="auto"
          spaceBetween={0}
          modules={[Navigation, FreeMode]}
          freeMode={true}
          speed={600}
          className="mySwiper h-full w-full justify-center pl-16 relative"
          navigation={{
            nextEl: '.custom-next',
            prevEl: '.custom-prev',
          }}
          slidesPerGroup={5}
          breakpoints={{
            640: {
              spaceBetween: 100,
            },
          }}
        >
          <SwiperSlide
            style={{
              width: 'auto',
            }}
          >
            <LocationTypeButton
              label="Visa alla"
              onClick={() => setLocationType(undefined)}
              name="all"
              isActive={activeType === undefined && !isFavorites}
              isVisible={isMinimized}
            />
          </SwiperSlide>
          {favorites.length > 0 && (
            <SwiperSlide
              style={{
                width: 'auto',
              }}
            >
              <LocationTypeButton
                label="Mina favoriter"
                onClick={() => setFavorites(favorites)}
                name="favorites"
                isActive={isFavorites}
                isVisible={isMinimized}
              />
            </SwiperSlide>
          )}
          {allLocationTypes.map((type, idx) => (
            <SwiperSlide
              key={idx}
              style={{
                width: 'auto',
              }}
            >
              <LocationTypeButton
                label={type.label}
                onClick={() => setLocationType(type.id)}
                name={type.name}
                is_disabled={!type.is_available}
                isActive={activeType === type.id}
              />
            </SwiperSlide>
          ))}
          <div
            className={`h-full w-4 absolute left-0 top-0 bg-gradient-to-l from-transparent to-accent md:to-secondary z-10
          ${pageState !== 1 ? 'visible' : 'invisible'}`}
          />
          <div
            className={`h-full w-4 absolute right-0 top-0 bg-gradient-to-r from-transparent to-accent md:to-secondary z-10
          ${pageState !== -1 ? 'visible' : 'invisible'}`}
          />
        </Swiper>
      </div>
    </div>
  );
};

BottomHeader.propTypes = {
  isMinimized: propTypes.bool,
  setLocationType: propTypes.func,
  isTypesLoading: propTypes.bool,
  setLocationIds: propTypes.func,
  isFavorites: propTypes.bool,
  setFavorites: propTypes.func,
};

export default BottomHeader;
