import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import getIcon from '../../util/getIcon';
import icon from '../../config/icons';

const LocationDetailsPlaceDescription = ({ title, description }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const descriptionRef = useRef(null);

  useEffect(() => {
    if (descriptionRef.current.scrollHeight > 100) {
      setIsOverflowing(true);
    }
  }, []);

  return (
    <div className="flex flex-col items-start w-full">
      <h3 className="text-2xl font-medium mb-6">{title}</h3>
      <div className="" ref={descriptionRef}>
        <p
          className={`transition-all text-text3 ${isExpanded ? '' : 'line-clamp-5'}`}
        >
          {description}
        </p>
        {isOverflowing && (
          <button
            className="flex items-center mt-2 cursor-pointer gap-2 "
            onClick={() => setIsExpanded(!isExpanded)}
            aria-expanded={isExpanded}
          >
            {isExpanded ? 'Läs mindre' : 'Läs mer'}
            {getIcon(icon.ARROW, `${isExpanded ? '' : 'rotate-180'}`)}
          </button>
        )}
      </div>
    </div>
  );
};

LocationDetailsPlaceDescription.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default LocationDetailsPlaceDescription;
