import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Marker } from 'react-map-gl';

const MapMarkerCluster = ({
  id,
  longitude,
  latitude,
  onClick,
  className,
  count,
  images,
}) => {
  const [isHover, setHover] = useState(false);

  return (
    <Marker longitude={longitude} latitude={latitude} key={id}>
      <div
        onClick={onClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className="group relative h-24 w-28 md:w-36 md:h-32 hover:scale-105 transition-all bounce"
      >
        {images?.map((image, idx) => (
          <div
            key={idx}
            style={{
              transform: `rotate(${(idx - (images.length - 1) / 2) * 10 * (isHover ? 2.5 : 1)}deg)`,
            }}
            className={`size-full
            absolute bg-secondary text-primary
               origin-bottom
                shadow-lg
               flex flex-col items-center justify-between rounded-3xl
               transition-all hover:scale-105 hover:skew-y-2 ${className}`}
          >
            <div className="p-4 relative size-full"></div>
            <img
              className="size-full p-2 absolute top-0 left-0 object-cover rounded-2xl"
              src={image}
              alt=""
            />
          </div>
        ))}
        <div className="absolute -top-6 -right-6 bg-secondary rounded-full p-4 size-8">
          <label className="text-2xl font-semibold absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 text-center text-primary">
            {`${count}`}
          </label>
        </div>
      </div>
    </Marker>
  );
};

MapMarkerCluster.propTypes = {
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default MapMarkerCluster;
