import { useQuery } from 'react-query';
import { useSetRecoilState } from 'recoil';
import actions from '../api/locations';
import { accessibilityTags } from '../state/filter';

const useFetchAccessibilityTags = () => {
  const setAccessibilityTags = useSetRecoilState(accessibilityTags);
  return useQuery(
    'useFetchAccessibilityTags',
    async () => {
      const data = await actions.getAccessibilityTags();
      setAccessibilityTags(data);
      return data;
    },
    {
      enabled: false,
    }
  );
};

export default useFetchAccessibilityTags;
