import React from 'react';
import propTypes from 'prop-types';

const InputField = ({ placeholder, style, text, setText }) => {
  return (
    <input
      placeholder={placeholder}
      className={`
      bg-secondary text-text1
      py-2 px-3 rounded-full outline-none
      ${style}`}
      onChange={(e) => setText(e.target.value)}
      value={text}
    ></input>
  );
};

InputField.propTypes = {
  placeholder: propTypes.string,
  style: propTypes.string,
  text: propTypes.string,
  setText: propTypes.func,
};

export default InputField;
