import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import getIcon from '../../../../util/getIcon';
import icon from '../../../../config/icons';
import MobileSearchField from './MobileSearchField';
import MobileFilterModal from './MobileFilterModal';
import disableBackgroundScroll from '../../../../util/disableBackgroundScroll';

const MobileFilter = ({ setHeaderState, setLocationIds }) => {
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);
  disableBackgroundScroll(isModalOpen);

  useEffect(() => {
    if (isModalOpen) {
      navigate('/');
    }
  }, [isModalOpen]);

  return (
    <>
      <div
        className={`${!isModalOpen ? 'opacity-100' : 'opacity-0'} transition-all duration-header
      flex justify-between space-x-2`}
      >
        <MobileSearchField onClick={() => setHeaderState(true)} />
        <button
          className="w-1/5 rounded-full p-2 shadow-2xl text-sm text-text2 -primary bg-secondary flex items-center justify-center"
          onClick={() => setModalOpen(true)}
        >
          {getIcon(icon.FILTER, 'text-lg')}
        </button>
      </div>
      <MobileFilterModal
        isOpen={isModalOpen}
        setOpen={setModalOpen}
        setLocationIds={setLocationIds}
      />
    </>
  );
};

MobileFilter.propTypes = {
  setHeaderState: PropTypes.func.isRequired,
  setLocationIds: PropTypes.func.isRequired,
};

export default MobileFilter;
