import { useQuery } from 'react-query';
import actions from '../api/counties';
import { useSetRecoilState } from 'recoil';
import { counties } from '../state/filter';

const useFetchCounties = () => {
  const setCounties = useSetRecoilState(counties);
  return useQuery(
    'useFetchCounties',
    async () => {
      const res = await actions.getCounties();
      setCounties(res.data);
      return res.data;
    },
    {
      enabled: false,
    }
  );
};

export default useFetchCounties;
