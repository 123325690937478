import React from 'react';
import PropTypes from 'prop-types';
import getIcon from '../../../../util/getIcon';
import icon from '../../../../config/icons';
import Loader from '../../loading/Loader';

const MobileFilterBar = ({
  title,
  onClick,
  buttonIcon,
  onTouchStart,
  onTouchMove,
  onTouchEnd,
  isLoading,
}) => {
  return (
    <div className="flex w-full flex-col items-center">
      <div
        className="w-full px-8 pt-3 pb-4 flex justify-center"
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        <div className="bg-primary rounded-full w-12 h-[5px] cursor-pointer"></div>
      </div>
      <div className="flex justify-between items-center w-full pb-5">
        <div onClick={onClick}>{buttonIcon}</div>
        {isLoading ? <Loader /> : <label>{title}</label>}
        <div>{getIcon(icon.FILTER, 'text-xl')}</div>
      </div>
    </div>
  );
};

MobileFilterBar.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  buttonIcon: PropTypes.any.isRequired,
  onTouchStart: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onTouchMove: PropTypes.func.isRequired,
  onTouchEnd: PropTypes.func.isRequired,
};

export default MobileFilterBar;
