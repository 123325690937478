import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InputField from '../../../InputField';
import SubmitButton from '../../../buttons/SubmitButton';
import RegionCheckBoxTree from './RegionCheckBoxTree';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { counties, locationTree } from '../../../../../state/filter';
import ClearFilterButton from '../../../../generic/buttons/ClearFilterButton';
import RegionPickerAll from './RegionPickerAll';

const RegionPicker = ({ availableOptions, onSubmit }) => {
  const [search, setSearch] = useState('');
  const [state, setState] = useRecoilState(locationTree);
  const resetState = useResetRecoilState(locationTree);
  const allOptions = useRecoilValue(counties);

  const handleReset = () => {
    resetState();
    onSubmit(null);
  };

  /*
  useEffect(() => {
    onSubmit(state.checked);
  }, [state.checked]);
  */

  return (
    <div className="flex flex-col space-y-2 h-full">
      <div className="flex justify-between bg-accent rounded-full p-2">
        <InputField
          placeholder="Sök på område..."
          style="w-2/4"
          text={search}
          setText={setSearch}
        />
        <SubmitButton
          label="Spara"
          style="w-1/4"
          onClick={() => onSubmit(state.checked)}
        />
      </div>
      <RegionPickerAll
        isChecked={state.checked.length === 0}
        onClick={handleReset}
        totalCount={availableOptions.location_ids?.length}
      />
      <div className="overflow-y-scroll h-full pt-2">
        <RegionCheckBoxTree
          allOptions={allOptions}
          availableOptions={availableOptions.municipalities}
          state={state}
          setState={setState}
          search={search}
        />
      </div>
      <div className="pt-2">
        {state.checked.length > 0 && (
          <ClearFilterButton
            onClick={handleReset}
            buttonStyle="w-fit ml-auto"
          />
        )}
      </div>
    </div>
  );
};

RegionPicker.propTypes = {
  availableOptions: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default RegionPicker;
