import React from 'react';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import icon from '../../config/icons';
import IconButton from '../generic/buttons/IconButton';
import ModalCard from '../generic/ModalCard';

const ShareModal = ({ isOpen, onClose, location }) => {
  const LOCATION_PATH = `${process.env.REACT_APP_FRONTEND_URL}platser/${location?.id}`;

  const shareItems = [
    {
      title: 'Kopiera',
      icon: icon.COPY,
      onClick: () => {
        navigator.clipboard.writeText(LOCATION_PATH);
        toast('Länk kopierad', {
          icon: '✅',
          position: 'top-center',
          style: {
            color: '#1E1E1E',
            background: '#FEFEFE',
            border: '1px solid #1E1E1E',
            padding: '16px',
          },
        });
      },
    },
    {
      title: 'Maila',
      icon: icon.EMAIL,
      onClick: () =>
        (window.location.href = `mailto:?subject=Dela platsen&body=Kolla in denna platsen: ${LOCATION_PATH}`),
    },
    {
      title: 'Facebook',
      icon: icon.FACEBOOK,
      onClick: () =>
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(LOCATION_PATH)}`,
          '_blank'
        ),
    },
    {
      title: 'Twitter',
      icon: icon.TWITTER,
      onClick: () =>
        window.open(
          `https://twitter.com/intent/tweet?url=${encodeURIComponent(LOCATION_PATH)}`,
          '_blank'
        ),
    },
    {
      title: 'Messenger',
      icon: icon.MESSENGER,
      onClick: () =>
        window.open(
          `https://www.facebook.com/dialog/send?link=${encodeURIComponent(LOCATION_PATH)}&app_id=YOUR_APP_ID&redirect_uri=${encodeURIComponent(LOCATION_PATH)}`,
          '_blank'
        ),
    },
  ];

  return (
    <ModalCard
      onClose={onClose}
      isOpen={isOpen}
      size="size-full md:max-h-[400px] md:max-w-[500px]"
    >
      <div className="my-auto space-y-20 md:space-y-2">
        <h2 className="text-2xl font-semibold">Dela platsen</h2>
        <div className="w-full flex space-x-2 mt-auto bg-secondary md:bg-accent rounded-2xl p-4">
          <img
            className="size-16 md:size-16 rounded-lg"
            src={location?.image}
            alt={location?.name}
          />
          <div className="px-2 flex flex-col justify-center">
            <h3 className="text-xl font-medium">{location?.name}</h3>
            <label className="text-sm">{location?.address}</label>
          </div>
        </div>
        <div className="share-grid w-full mt-auto">
          {shareItems.map((item, idx) => (
            <IconButton
              buttonStyle="md:w-48 space-x-6 rounded-lg"
              key={idx}
              onClick={item.onClick}
              text={item.title}
              icon={item.icon}
              isDark={false}
            />
          ))}
        </div>
      </div>
    </ModalCard>
  );
};

ShareModal.propTypes = {
  location: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

export default ShareModal;
