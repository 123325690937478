import device from '../config/device';

const getDeviceType = () => {
  const width = window.innerWidth;
  if (width < 640) {
    return device.PHONE;
  } else if (width < 1280) {
    return device.TABLET;
  } else if (width < 1920) {
    return device.LAPTOP;
  } else {
    return device.DESKTOP;
  }
};

export default getDeviceType;
