import React from 'react';
import SkeletonBox from '../generic/loading/SkeletonBox';

const LocationDetailsLoader = () => {
  return (
    <div className="w-full flex flex-col">
      <div className="w-full h-fit flex justify-between mb-4">
        <SkeletonBox className="h-9 w-1/3 rounded-3xl" />
        <div className="w-full flex justify-end space-x-3">
          <SkeletonBox className="h-12 w-14 md:w-36 rounded-3xl" />
          <SkeletonBox className="h-12 w-14 md:w-24 rounded-3xl" />
        </div>
      </div>
      <div className="w-full md:space-x-3 md:flex h-[300px] md:h-[450px] xl:h-[600px] mb-6">
        <div className="md:flex flex-col h-full w-1/2 space-y-3 hidden">
          <SkeletonBox className="rounded-2xl h-1/2 w-full" />
          <div className="flex space-x-3 h-1/2">
            <SkeletonBox className="rounded-2xl h-full w-1/2" />
            <SkeletonBox className="rounded-2xl h-full w-1/2" />
          </div>
        </div>
        <SkeletonBox className="rounded-2xl h-full w-full md:w-1/2" />
      </div>
      <div className="w-full flex justify-between mb-4">
        <div className="flex flex-col gap-2 w-1/2">
          <SkeletonBox className="h-10 w-3/4 rounded-3xl" />
          <SkeletonBox className="h-5 w-2/5 rounded-3xl" />
        </div>
        <SkeletonBox className="h-10 w-32 rounded-full" />
      </div>
      <div className="w-full h-96 md:space-x-3 flex">
        <SkeletonBox className="h-full w-1/2 rounded-3xl hidden md:block" />
        <SkeletonBox className="h-full w-full md:w-1/2 rounded-3xl" />
      </div>
    </div>
  );
};

export default LocationDetailsLoader;
