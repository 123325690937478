import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import PropTypes from 'prop-types';
import { EffectCoverflow, EffectCube, EffectFlip } from 'swiper/modules';
import { useRecoilValue } from 'recoil';
import { accessibilityTags, locationTypes } from '../../../state/filter';
import getTagIcon from '../../../util/getTagIcon';
import tagType from '../../../config/tagType';

import MapSingleLocation from './MapSingleLocation';
import MapLocationList from './list/MapLocationList';
import MapSingleLocationLoader from './MapSingleLocationLoader';

import 'swiper/css';
import 'swiper/css/effect-flip';

const MapModal = ({
  locations,
  locationsQueryResult,
  isLocationsLoading,
  onLocationClick,
  focusLocation,
  locationData,
  isSingleLocationLoading,
  is360View,
}) => {
  const [swiper, setSwiper] = useState(null);
  const allAccessibilityTags = useRecoilValue(accessibilityTags);
  const allLocationTypes = useRecoilValue(locationTypes);
  const locationType = allLocationTypes.find(
    (t) => t.id === Number.parseInt(locationData?.location_type)
  );
  const allTags = allAccessibilityTags.flatMap((cat) => cat.tags);

  const getTags = (ids) => {
    return allTags.filter((tag) => ids.includes(tag.id));
  };

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(focusLocation ? 1 : 0);
    }
  }, [focusLocation]);

  return (
    <div
      className={`
       bg-secondary
        absolute 
        max-w-[20vw] min-w-[350px] w-[400px]
        h-[78%]
        hidden md:block
        rounded-xl shadow-xl
        top-10 left-10 z-10
        p-4`}
    >
      <Swiper
        onSwiper={setSwiper}
        allowTouchMove={false}
        effect="coverflow"
        grabCursor={true}
        modules={[EffectCoverflow]}
        className="mySwiper size-full"
      >
        <SwiperSlide className="size-full">
          <MapLocationList
            locations={locations}
            locationsQueryResult={locationsQueryResult}
            onSelect={(location) => onLocationClick(location)}
            isLoading={isLocationsLoading}
            locationTypes={allLocationTypes}
          />
        </SwiperSlide>
        <SwiperSlide className="size-full">
          {isSingleLocationLoading && <MapSingleLocationLoader />}
          {locationData && (
            <MapSingleLocation
              location={locationData}
              onClose={() => onLocationClick(null)}
              is360View={is360View}
              locationTypeName={locationType?.label}
              locationIcon={getTagIcon(
                locationType?.name,
                tagType.LOCATION_TYPE,
                '24'
              )}
              accessibilityTags={getTags(locationData.accessibility_tags)}
            />
          )}
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

MapModal.propTypes = {
  locations: PropTypes.array.isRequired,
  locationsQueryResult: PropTypes.object.isRequired,
  onLocationClick: PropTypes.func.isRequired,
  focusLocation: PropTypes.any,
  locationData: PropTypes.any,
  is360View: PropTypes.bool.isRequired,
  isSingleLocationLoading: PropTypes.bool.isRequired,
  isLocationsLoading: PropTypes.bool.isRequired,
};

export default MapModal;
