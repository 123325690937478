import React, { useEffect, useState } from 'react';
import MobileFilterWrapper from '../MobileFilterWrapper';
import PropTypes from 'prop-types';
import generateFilterTextArray from '../../../../../util/generateFilterTextArray';
import MobileFilterTag from '../MobileFilterTag';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import {
  counties,
  locationTree as LocationTreeState,
} from '../../../../../state/filter';

const MobileFilterLocation = ({ title, description, onSubmit, setActive }) => {
  const [tagLabels, setTagLabels] = useState(['Hela sverige']);
  const locationTree = useRecoilValue(LocationTreeState);
  const allCounties = useRecoilValue(counties);
  const resetState = useResetRecoilState(LocationTreeState);

  const handleClearFilter = () => {
    resetState();
    onSubmit([]);
  };

  useEffect(() => {
    setTagLabels(
      generateFilterTextArray(
        allCounties.flatMap((county) => county.municipalities),
        locationTree.checked,
        'Hela sverige'
      )
    );
  }, [locationTree]);

  return (
    <MobileFilterWrapper
      title={title}
      description={description}
      onClearFilter={handleClearFilter}
      isFiltered={locationTree.checked.length > 0}
      onSubmit={() => onSubmit(locationTree.checked)}
      setActive={setActive}
      selectedComponents={
        <div className="flex flex-wrap">
          {tagLabels.map((word, idx) => (
            <MobileFilterTag style="mr-2 mb-2" key={idx} label={word} />
          ))}
        </div>
      }
    />
  );
};

MobileFilterLocation.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setActive: PropTypes.func.isRequired,
};

export default MobileFilterLocation;
