import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useMutateFetchPage from '../hooks/useFetchPage';
import setTitle from '../util/setTitle';
import icon from '../config/icons.js';
import getIcon from '../util/getIcon.js';
import ContactModal from '../components/LocationDetailsPage/ContactModal';

const GenericPage = () => {
  const { pageSlug } = useParams();
  const { data, mutate } = useMutateFetchPage(pageSlug);
  const [isContactModal, setContactModal] = useState(false);

  useEffect(() => {
    mutate(pageSlug);
  }, [pageSlug]);

  useEffect(() => {
    if (data) {
      setTitle(data.title);
      const headers = document.querySelectorAll('.accordion-header');
      headers.forEach((header) => {
        const button = document.createElement('span');
        button.className = 'button';
        button.textContent = 'Se svar +';
        header.appendChild(button);

        header.addEventListener('click', () => {
          const body = header.nextElementSibling;
          body.classList.toggle('accordion-active');
        });
      });
    }
  }, [data]);

  if (!data) {
    return <>Laddar</>;
  }

  return (
    <>
      <div
        className="
      w-full min-h-[calc(80vh-145px)] mx-auto
      mt-[145px]
      bg-secondary
      flex flex-col md:flex-row
      "
      >
        <div className="flex flex-col md:flex-row items-stretch mx-auto md:py-20">
          <div className={`flex ${data.image ? 'md:w-2/3' : 'w-full'}`}>
            <div className="md:mx-12 my-auto w-full max-h-2/3 space-y-4">
              <div
                className="genericContent"
                dangerouslySetInnerHTML={{ __html: data?.body }}
              />
              <button
                className="items-center mx-auto flex space-x-2 underline"
                onClick={() => setContactModal(true)}
              >
                {getIcon(icon.EMAIL)}
                <p>Kontakta oss</p>
              </button>
            </div>
          </div>
          <ContactModal
            onClose={() => setContactModal(false)}
            isOpen={isContactModal}
          />
          {data.image && (
            <div className="w-full md:w-1/3 flex mt-4 md:mt-0 md:mr-12">
              <img className="w-full object-fit rounded-2xl" src={data.image} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default GenericPage;
