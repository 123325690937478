import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import LocationTag from './LocationTag';
import skeleton from '../../assets/skeleton.png';
import getIcon from '../../util/getIcon';
import icon from '../../config/icons';

const LocationListItem = ({
  id,
  loadIndex,
  municipality,
  name,
  image,
  accessibilityTags,
  setFavorite,
  isFavorite,
}) => {
  const [highlightTag, setHighlightTag] = useState(null);
  const [isImageLoading, setImageLoading] = useState(true);
  const MAX_ICONS = 4;

  return (
    <div className="size-full p-2 animate-fadeIn">
      <div className="overflow-hidden rounded-lg relative">
        <div className="aspect-square group">
          <button
            className="absolute top-2 right-2 rounded-full bg-secondary p-2
            transition-all hover:scale-[1.1]"
            onClick={setFavorite}
          >
            {isFavorite ? getIcon(icon.HEART_FILLED) : getIcon(icon.HEART)}
          </button>
          <Link to={`platser/${id}`} className="size-full">
            <img
              alt={`Huvudbild för ${name}`}
              className="hover:opaity-90 object-cover size-full transition-all ease-in-out"
              src={isImageLoading ? skeleton : image}
              onLoad={() => setImageLoading(false)}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src =
                  'https://png.pngtree.com/png-clipart/20190619/original/pngtree-vector-picture-icon-png-image_4013511.jpg';
              }}
            />
          </Link>
          <div
            className={`absolute bottom-0 w-full bg-secondary/90 flex
              ease-out
               ${highlightTag ? 'h-1/2 px-4 py-2' : 'h-0 overflow-hidden'} transition-all duration-500`}
          >
            {highlightTag && (
              <div className="flex flex-col">
                <label className="text-text2 text-sm mb-2">
                  Platsen är anpassad för
                </label>
                <label className="text-text1 text-lg font-semibold">
                  {highlightTag?.label}
                </label>
                <p>{highlightTag?.description}</p>
              </div>
            )}
          </div>
        </div>
        <div className="h-full w-full absolute animate-image left-0 top-0 pointer-events-none" />
      </div>
      <div className="flex pt-2">
        <div className="w-full flex flex-col space-y-1">
          <p className="text-base font-medium text-text1 leading-4">{name}</p>
          <h3 className="text-sm text-text2 leading-4">{municipality}</h3>
        </div>
        <div className="items-center flex">
          <div className="flex space-x-2">
            {accessibilityTags
              ?.slice(
                0,
                accessibilityTags.length === MAX_ICONS
                  ? MAX_ICONS
                  : MAX_ICONS - 1
              )
              .map((t, idx) => (
                <LocationTag
                  key={idx}
                  name={t.name}
                  image={t.image}
                  onHover={() => setHighlightTag(t)}
                  onHoverExit={() => setHighlightTag(null)}
                  className="border border-transparent hover:border-border"
                  size="24"
                />
              ))}
            {accessibilityTags?.length > MAX_ICONS && (
              <LocationTag
                label={`+${accessibilityTags?.length - MAX_ICONS}`}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

LocationListItem.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  address: PropTypes.string,
  image: PropTypes.string,
  accessibilityTags: PropTypes.array,
  setFavorite: PropTypes.func,
  isFavorite: PropTypes.bool,
};

export default LocationListItem;
