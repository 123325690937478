import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import InputField from '../../../InputField';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import {
  accessibilityTags,
  selectedAccessibilityTypes,
} from '../../../../../state/filter';
import SubmitButton from '../../../buttons/SubmitButton';
import getTagIcon from '../../../../../util/getTagIcon';
import tagType from '../../../../../config/tagType';
import FilterTypePickerButton from './FilterTypePickerButton';
import ClearFilterButton from '../../../buttons/ClearFilterButton';

const AccessibilityPicker = ({ availableOptions, onSubmit }) => {
  const [search, setSearch] = useState('');
  const allAccessibilityTypes = useRecoilValue(accessibilityTags);
  const [selected, setSelected] = useRecoilState(selectedAccessibilityTypes);
  const resetSelected = useResetRecoilState(selectedAccessibilityTypes);

  const handleReset = () => {
    resetSelected();
    onSubmit(null);
  };

  useEffect(() => {
    setSelected((prevSelected) =>
      prevSelected.filter((id) =>
        availableOptions.some((option) => option.id === id)
      )
    );
  }, [availableOptions]);

  const filteredAccessibilityTypes = allAccessibilityTypes
    .filter((t) => t.label.toLowerCase().startsWith(search.toLowerCase()))
    .sort((a, b) => {
      const aInData = availableOptions?.find((o) => o.id == a.id)?.count || -1;
      const bInData = availableOptions?.find((o) => o.id == b.id)?.count || -1;
      return aInData < bInData;
    });

  const onSelect = (id) => {
    setSelected((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  return (
    <div className="flex flex-col space-y-2 h-full select-none">
      <div className="flex justify-between bg-accent rounded-full p-2">
        <InputField
          placeholder="Sök på tillgänglighet"
          style="w-2/3"
          text={search}
          setText={setSearch}
        />
        <SubmitButton
          label="Spara"
          style="w-1/4"
          onClick={() => onSubmit(selected)}
        />
      </div>
      <div className="overflow-y-scroll size-full space-y-2">
        {filteredAccessibilityTypes.map((categories, idx) => (
          <div key={idx} className="border-b border-border">
            <label className="text-text2">{categories.label}:</label>
            <div className="size-full dropdown-grid">
              {categories.tags.map((type, idx) => {
                const option = availableOptions?.find((o) => o.id == type.id);
                return (
                  <FilterTypePickerButton
                    key={idx}
                    label={`${type.label} ${option ? `(${option.count})` : ''}`}
                    isDisabled={!option}
                    id={type.id}
                    onClick={() => onSelect(type.id)}
                    isActive={selected.includes(type.id)}
                    icon={getTagIcon(type.name, tagType.ACCESSIBILITY)}
                  />
                );
              })}
            </div>
          </div>
        ))}
      </div>
      <div className="pt-2">
        {selected.length > 0 && (
          <ClearFilterButton
            onClick={handleReset}
            buttonStyle="w-fit ml-auto"
          />
        )}
      </div>
    </div>
  );
};

AccessibilityPicker.propTypes = {
  availableOptions: propTypes.array,
  onSubmit: propTypes.func,
};

export default AccessibilityPicker;
