import React from 'react';
import PropTypes from 'prop-types';
import getTagIcon from '../../util/getTagIcon';
import tagType from '../../config/tagType';

const LocationDetailsTagsList = ({ accessibilityTags }) => {
  return (
    <div className="flex flex-col items-start">
      <h3 className="text-2xl font-medium mb-6">
        Denna plats har tlllgänglighet för
      </h3>
      <div className="flex gap-4 flex-wrap">
        {accessibilityTags?.map((item, idx) => (
          <div
            className="p-4 border rounded-lg flex flex-col items-center gap-y-2"
            key={idx}
          >
            {getTagIcon(item.name, tagType.ACCESSIBILITY)}
            <p className="text-xs font-semibold">{item.label}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

LocationDetailsTagsList.propTypes = {
  accessibilityTags: PropTypes.array,
};

export default LocationDetailsTagsList;
