import React from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

const LocationGallerySwiper = ({ images, onImageClick }) => {
  return (
    <div className="size-full">
      <Swiper
        style={{
          '--swiper-pagination-color': '#1E1E1E',
          '--swiper-pagination-bullet-inactive-color': '#999999',
          '--swiper-pagination-bullet-inactive-opacity': '1',
          '--swiper-pagination-bullet-size': '6px',
          '--swiper-pagination-bullet-horizontal-gap': '6px',
        }}
        pagination={{
          dynamicBullets: true,
        }}
        className="size-full"
        spaceBetween={50}
        modules={[Pagination]}
        freeMode={true}
        speed={600}
      >
        {images.map((image, idx) => (
          <SwiperSlide key={idx}>
            <img
              className="rounded-2xl"
              src={image.image}
              alt={image.description}
              onClick={onImageClick}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

LocationGallerySwiper.propTypes = {
  images: PropTypes.array.isRequired,
  onImageClick: PropTypes.func.isRequired,
};

export default LocationGallerySwiper;
