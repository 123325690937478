import { atom } from 'recoil';

export const view = atom({
  key: 'view',
  default: 'map',
});

export const isOverlay = atom({
  key: 'isOverlay',
  default: false,
});
