import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { isOverlay } from '../../state/view';
import { useSetRecoilState } from 'recoil';
import CloseButton from './buttons/CloseButton';
import closeOnOutsideClickFunc from '../../util/closeOnOutsideClickFunc';

const ModalCard = ({ size, padding, children, isOpen, onClose }) => {
  const ref = useRef();
  const setOverlay = useSetRecoilState(isOverlay);

  const handleClose = () => {
    if (isOpen) {
      onClose();
      setOverlay(false);
    }
  };

  useEffect(() => {
    setOverlay(isOpen);
  }, [isOpen, setOverlay]);

  // Use the custom hook to close on outside click
  closeOnOutsideClickFunc(ref, handleClose);

  return (
    <div
      className={`
        ${size || 'md:h-4/5 w-4/5'}
        bg-accent md:bg-secondary fixed 
        md:rounded-2xl md:border border-border
        ${padding || ' p-8 md:p-10'}
        transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 z-30 flex flex-col
        ${isOpen ? 'opacity-1 animate-popDown' : 'opacity-0 animate-popUp transition-all duration-500 pointer-events-none'}
      `}
      ref={ref}
    >
      {children}
      {isOpen && (
        <CloseButton
          buttonStyle="absolute top-6 right-6 z-20 text-[28px]"
          onClick={handleClose}
        />
      )}
    </div>
  );
};

ModalCard.propTypes = {
  size: PropTypes.string,
  padding: PropTypes.string,
  children: PropTypes.any.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ModalCard;
