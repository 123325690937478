import React, { useRef } from 'react';
import propTypes from 'prop-types';
import getIcon from '../../../../util/getIcon';
import icon from '../../../../config/icons';

const MobileSearchField = ({ onClick }) => {
  const inputRef = useRef();

  const handleInputFocus = () => {
    onClick();
  };

  return (
    <div className="size-full relative">
      <input
        disabled
        ref={inputRef}
        className="w-full h-full absolute
              bg-secondary shadow-md rounded-full py-4 px-12
              select-none hover:bg-hover relative outline-none"
        onClick={handleInputFocus}
      />
      <div className="flex space-x-4 items-center size-full absolute top-0 left-4 pointer-events-none">
        {getIcon(icon.SEARCH)}
        <p className="text-sm text-text4">Klicka här för att påbörja sök</p>
      </div>
    </div>
  );
};

MobileSearchField.propTypes = {
  onClick: propTypes.func.isRequired,
};

export default MobileSearchField;
