import React, { useState } from 'react';
import PropTypes from 'prop-types';

const LocationDetailsRichTextMenu = ({ richTextItems }) => {
  const [currentItem, setCurrentItem] = useState(0);

  return (
    <div>
      <h3 className="text-2xl mb-6 font-medium">Information</h3>
      <div>
        <ol className="flex space-x-4 mb-6 overflow-x-scroll">
          {richTextItems.map((item, idx) => (
            <button
              key={idx}
              onClick={() => setCurrentItem(idx)}
              className={`rounded-full px-4 py-1 hover:bg-primary hover:text-secondary
                    transition-all whitespace-nowrap
                    ${currentItem === idx ? 'bg-primary text-secondary' : 'bg-secondary text-primary'}`}
            >
              {item.title}
            </button>
          ))}
        </ol>
        <p
          className="text-text3"
          dangerouslySetInnerHTML={{
            __html: richTextItems[currentItem].description,
          }}
        />
      </div>
    </div>
  );
};

LocationDetailsRichTextMenu.propTypes = {
  richTextItems: PropTypes.array,
};

export default LocationDetailsRichTextMenu;
