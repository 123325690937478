import React, { useEffect, useState } from 'react';
import ReviewStars from './ReviewStars';

const ReviewSummaryCard = ({ score, reviews }) => {
  const [data, setData] = useState([
    { type: 5, count: 0 },
    { type: 4, count: 0 },
    { type: 3, count: 0 },
    { type: 2, count: 0 },
    { type: 1, count: 0 },
  ]);

  useEffect(() => {
    if (reviews) {
      const updatedData = data.map((item) => {
        const count = reviews.filter(
          (review) => Math.floor(parseFloat(review.rating)) === item.type
        ).length;
        return { ...item, count };
      });
      setData(updatedData);
    }
  }, [reviews]);

  return (
    <div className="border border-border rounded-xl flex divide-x divide-border">
      <div className="flex flex-col p-5 w-1/3 space-y-1 items-start justify-center">
        <label className="text-4xl">{score.toFixed(1)}</label>
        <div>{<ReviewStars rating={score} />}</div>
        <p className="text-text2">{`Baserat på ${reviews.length} omdömen`}</p>
      </div>
      <ol className="p-5 w-2/3">
        {data.map((item, idx) => (
          <li className="w-full flex items-center justify-between" key={idx}>
            {<ReviewStars rating={item.type} />}
            <div className="w-1/3 bg-hover h-2 rounded-full overflow-hidden">
              <div
                className="bg-primary h-full"
                style={{
                  width: `${(item.count / 5) * 100}%`,
                }}
              ></div>
            </div>
            <label className="text-text3">{item.count}</label>
          </li>
        ))}
      </ol>
    </div>
  );
};

ReviewSummaryCard.propTypes = {};

export default ReviewSummaryCard;
