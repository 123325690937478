import React from 'react';
import PropTypes from 'prop-types';

const ClearFilterButton = ({ onClick, buttonStyle }) => {
  return (
    <>
      <button
        className={`md:hidden ${buttonStyle} px-4 py-1 bg-accent rounded-md text-text1 clear-filter`}
        onClick={onClick}
      >
        Rensa
      </button>
      <button
        className={`hidden md:flex ${buttonStyle} px-4 py-1 bg-accent rounded-md text-text1 clear-filter`}
        onClick={onClick}
      >
        Rensa
      </button>
    </>
  );
};

ClearFilterButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  buttonStyle: PropTypes.string,
};

export default ClearFilterButton;
