const icon = {
  SEARCH: 'search',
  FILTER: 'filter',
  ARROW: 'arrow',
  MENU: 'menu',
  CLOSE: 'closes',
  CHECKED: 'checked',
  UNCHECKED: 'unchecked',
  HALF_CHECKED: 'half_checked',
  PRINT: 'print',
  SHARE: 'share',
  COPY: 'copy',
  EMAIL: 'email',
  FACEBOOK: 'facebook',
  CHECK: 'check',
  TWITTER: 'twitter',
  MESSENGER: 'messenger',
  HEART: 'heart',
  HEART_FILLED: 'heart_filled',
  PIN: 'pin',
  MAP: 'map',
  IMAGE: 'image',
  _360: '360',
  DIRECTIONS: 'directions',
  PLUS: 'plus',
  MINUS: 'minus',
  NAVIGATION: 'navigation',
  NAVIGATION_FILLED: 'navigation_filled',
  FLAG: 'flag',
  STAR: 'star',
};

export default icon;
