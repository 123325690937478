import React from 'react';
import propTypes from 'prop-types';

const SubmitButton = ({ label, onClick, isDisabled, style, icon }) => {
  return (
    <button
      disabled={isDisabled}
      className={`flex items-center bg-primary text-secondary
      py-2 shadow rounded-full size-10 justify-center relative
      transition-all 
        ${isDisabled ? 'opacity-50' : 'md:hover:scale-110'} ${style}`}
      onClick={onClick}
    >
      {label}
      <div className="absolute right-4">{icon}</div>
    </button>
  );
};

SubmitButton.propTypes = {
  label: propTypes.string,
  isDisabled: propTypes.bool,
  onClick: propTypes.func,
  style: propTypes.string,
  icon: propTypes.any,
};

export default SubmitButton;
