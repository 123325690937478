import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import getIcon from '../../../../../util/getIcon';
import icon from '../../../../../config/icons';

const RegionCheckBoxTree = ({
  allOptions,
  availableOptions,
  state,
  setState,
  search,
}) => {
  const [nodes, setNodes] = useState([]);

  const getNodes = useCallback(
    (searchTerm) => {
      if (!allOptions) return [];

      const matchesSearch = (name) =>
        name.toLowerCase().startsWith(searchTerm.toLowerCase());

      const filteredNodes = allOptions
        .map((county) => {
          const municipalitiesMatch = county.municipalities.filter(
            (municipality) => matchesSearch(municipality.name)
          );

          if (matchesSearch(county.name) || municipalitiesMatch.length > 0) {
            const availableCountyLocations = county.municipalities.reduce(
              (sum, m) =>
                sum +
                (availableOptions?.find((o) => o.id === m.id)?.count || 0),
              0
            );

            const children = county.municipalities
              .map((municipality) => {
                const availableMunicipalityLocations =
                  availableOptions?.find((o) => o.id === municipality.id)
                    ?.count || 0;
                return {
                  id: municipality.id,
                  municipality: municipality.name,
                  amount: availableMunicipalityLocations,
                  disabled: availableMunicipalityLocations === 0,
                  count: availableMunicipalityLocations,
                };
              })
              .sort((a, b) => b.count - a.count);

            return {
              id: county.id,
              county: county.name,
              amount: availableCountyLocations,
              disabled: availableCountyLocations === 0,
              children,
              count: availableCountyLocations,
            };
          }
          return null;
        })
        .filter(Boolean)
        .sort((a, b) => b.count - a.count);

      setState((prevState) => ({
        ...prevState,
        expanded: searchTerm ? filteredNodes.map((node) => node.id) : [],
      }));

      return filteredNodes;
    },
    [allOptions, availableOptions, search, setState]
  );

  useEffect(() => {
    if (allOptions) {
      const newNodes = getNodes(search);
      setNodes(newNodes);
    }
  }, [allOptions, search, getNodes]);

  useEffect(() => {
    const newChecked = state.checked.filter((id) =>
      availableOptions.some((option) => option.id === id)
    );
    if (JSON.stringify(newChecked) !== JSON.stringify(state.checked)) {
      setState((prevState) => ({
        ...prevState,
        checked: newChecked,
      }));
    }
  }, [availableOptions, state.checked, setState]);

  const toggleExpand = useCallback(
    (id) => {
      setState((prevState) => ({
        ...prevState,
        expanded: prevState.expanded.includes(id)
          ? prevState.expanded.filter((item) => item !== id)
          : [...prevState.expanded, id],
      }));
    },
    [setState]
  );

  const toggleCheck = useCallback(
    (id) => {
      setState((prevState) => ({
        ...prevState,
        checked: prevState.checked.includes(id)
          ? prevState.checked.filter((item) => item !== id)
          : [...prevState.checked, id],
      }));
    },
    [setState]
  );

  const toggleParentCheck = useCallback(
    (parent) => {
      const parentChecked = [...state.parentChecked];
      const checked = [...state.checked];
      const childIds = parent.children
        .filter((c) => !c.disabled)
        .map((c) => c.id);
      const withoutChildren = checked.filter(
        (item) => !childIds.includes(item)
      );

      if (parentChecked.includes(parent.id)) {
        setState({
          ...state,
          checked: withoutChildren,
          parentChecked: parentChecked.filter((p) => p !== parent.id),
        });
      } else {
        setState({
          ...state,
          checked: withoutChildren.concat(childIds),
          parentChecked: [...parentChecked, parent.id],
        });
      }
    },
    [setState, state]
  );

  return (
    <div className="size-full space-y-2">
      {nodes.map((node) => (
        <div key={node.id}>
          <button
            className="flex items-center justify-between hover:bg-hover px-2 w-full"
            onClick={() => toggleExpand(node.id)}
            disabled={node.disabled}
          >
            <div className="flex space-x-2 items-center">
              <input
                type="checkbox"
                className="size-5 accent-primary"
                disabled={node.disabled}
                checked={node.children.some((c) =>
                  state.checked.includes(c.id)
                )}
                onChange={() => toggleParentCheck(node)}
              />
              <label
                className={`${node.disabled ? 'text-text4' : ''} text-text1 text-lg`}
              >
                {node.county}
                {node.amount > 0 && (
                  <span className="text-text4">{` (${node.amount})`}</span>
                )}
              </label>
            </div>
            {!node.disabled &&
              getIcon(
                icon.ARROW,
                `${state.expanded.includes(node.id) ? 'rotate-180' : 'rotate-90'} transition-rotation ease-in-out duration-100`
              )}
          </button>
          <div
            className={`${
              state.expanded.includes(node.id) ? 'max-h-[800px]' : 'max-h-0'
            } flex flex-col ml-4 overflow-hidden space-y-4 transition-height duration-100 ease-in-out pt-2`}
          >
            {node.children.map((child) => (
              <button
                key={child.id}
                className="flex items-center space-x-1 hover:bg-hover px-2"
                onClick={() => toggleCheck(child.id)}
                disabled={child.disabled}
              >
                <input
                  type="checkbox"
                  className="size-5 accent-primary"
                  checked={state.checked.includes(child.id)}
                  disabled={child.disabled}
                  readOnly={true}
                />
                <label
                  className={`${child.disabled ? 'text-text4' : ''} text-text1`}
                >
                  {child.municipality}
                  {child.amount > 0 && (
                    <span className="text-text4">{` (${child.amount})`}</span>
                  )}
                </label>
              </button>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

RegionCheckBoxTree.propTypes = {
  allOptions: PropTypes.array.isRequired,
  availableOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      count: PropTypes.number.isRequired,
    })
  ),
  state: PropTypes.shape({
    checked: PropTypes.array.isRequired,
    parentChecked: PropTypes.array.isRequired,
    expanded: PropTypes.array.isRequired,
  }).isRequired,
  setState: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
};

export default RegionCheckBoxTree;
