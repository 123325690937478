import React from 'react';
import PropTypes from 'prop-types';
import getIcon from '../../../util/getIcon';
import icon from '../../../config/icons';

const CloseButton = ({ onClick, buttonStyle }) => {
  return (
    <button
      onClick={onClick}
      className={`${buttonStyle} rounded-full hover:bg-hover p-1`}
    >
      {getIcon(icon.CLOSE)}
    </button>
  );
};

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  buttonStyle: PropTypes.string,
};

export default CloseButton;
