import React from 'react';

const RWDDebugger = () => {
  const debug = process.env.REACT_APP_DEBUG;

  if (!debug) {
    return null;
  }

  return (
    <div className="fixed top-8 left-4 text-red-500 text-lg z-40 space-x-2">
      <label className="block md:hidden">sm</label>
      <label className="hidden md:block lg:hidden">md</label>
      <label className="hidden lg:block xl:hidden">lg</label>
      <label className="hidden xl:block">xl</label>
    </div>
  );
};

export default RWDDebugger;
