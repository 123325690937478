import React, { useMemo, useEffect, useState } from 'react';
import Map from 'react-map-gl';
import MapMarker from './MapMarker';
import PropTypes from 'prop-types';
import useSupercluster from 'use-supercluster';
import MapMarkerCluster from './MapMarkerCluster';

import 'mapbox-gl/dist/mapbox-gl.css';

const MapComponent = ({
  focusLocation,
  locations,
  allLocationTypes,
  onMarkerClick,
  mapRef,
  viewPort,
  setViewPort,
  isLoading,
}) => {
  const API_KEY = process.env.REACT_APP_MAP_BOX_API_KEY;
  const map_id = 'mapbox://styles/williamwith/clyioll05010801qp67ju3cg8';

  const points = locations.map((location) => ({
    type: 'Feature',
    properties: {
      cluster: false,
      locationId: location.id,
      locationName: location.name,
      locationImage: location.image,
      locationTypeName: allLocationTypes.find(
        (t) => t.id === location.location_type
      )?.name,
    },
    geometry: {
      type: 'Point',
      coordinates: [
        location.coordinates[1], // Longitude
        location.coordinates[0], // Latitude
      ],
    },
  }));

  const bounds = mapRef.current?.getBounds()?.toArray().flat() || null;

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom: viewPort.zoom,
    options: {
      minZoom: 0,
      maxZoom: 16,
      radius: 80,
      extent: 512,
      nodeSize: 64,
    },
  });

  const flyToCoords = (lng, lat, zoom, speed = 3.5) => {
    if (mapRef.current) {
      mapRef.current.flyTo({
        center: [lng, lat],
        zoom: zoom,
        speed: speed,
        curve: 2.5,
        essential: true,
      });
    }
  };

  const handleMarkerClick = (data) => {
    onMarkerClick({
      id: data.properties.locationId,
      coordinates: data.geometry.coordinates,
    });
  };

  useEffect(() => {
    if (!mapRef.current) {
      return;
    }
    let zoom = 7;
    let lng = viewPort.longitude;
    let lat = viewPort.latitude;

    if (focusLocation) {
      lng = focusLocation.coordinates[0];
      lat = focusLocation.coordinates[1];
      zoom = 18;
    }

    if (lat && lng) {
      flyToCoords(lng, lat, zoom);
    }
  }, [focusLocation]);

  const markers = useMemo(
    () =>
      clusters.map((cluster) => {
        const [longitude, latitude] = cluster.geometry.coordinates;
        const {
          cluster: isCluster,
          point_count: pointCount,
          locationTypeName,
          locationId,
          locationName,
          locationImage,
        } = cluster.properties;

        if (isCluster && !isLoading) {
          const leaves = supercluster.getLeaves(cluster.id, 5);
          const images = leaves?.map((leaf) => leaf.properties.locationImage);

          return (
            <MapMarkerCluster
              key={cluster.id}
              id={cluster.id}
              count={pointCount}
              longitude={longitude}
              latitude={latitude}
              onClick={() => {
                const zoom = supercluster.getClusterExpansionZoom(cluster.id);
                flyToCoords(longitude, latitude, zoom, 1.5);
              }}
              images={images}
            />
          );
        } else {
          return (
            <MapMarker
              key={locationId}
              id={locationId}
              longitude={longitude}
              latitude={latitude}
              locationName={locationName}
              locationImage={locationImage}
              locationTypeName={locationTypeName}
              onClick={() => handleMarkerClick(cluster)}
            />
          );
        }
      }),
    [clusters, onMarkerClick, flyToCoords]
  );

  return (
    <Map
      initialViewState={{ ...viewPort }}
      onMove={(evt) => setViewPort(evt.viewState)}
      ref={mapRef}
      mapboxAccessToken={API_KEY}
      // mapStyle={map_id}
      mapStyle="mapbox://styles/mapbox/streets-v11"
    >
      {markers}
    </Map>
  );
};

MapComponent.propTypes = {
  focusLocation: PropTypes.object,
  locations: PropTypes.array.isRequired,
  allLocationTypes: PropTypes.array.isRequired,
  onMarkerClick: PropTypes.func.isRequired,
  mapRef: PropTypes.any.isRequired,
};

export default MapComponent;
