import React from 'react';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import icon from '../../config/icons';
import IconButton from '../generic/buttons/IconButton';
import { removeSavedLocation, saveLocation } from '../../util/savedLocations';
import IconLinkButton from '../generic/buttons/IconLinkButton';
import { useRecoilState } from 'recoil';
import { favoritesLocationIds } from '../../state/location';

const LocationDetailsUpperSection = ({ title, locationId, handleShare }) => {
  const [favoriteLocations, setFavoriteLocations] =
    useRecoilState(favoritesLocationIds);
  const isSaved = favoriteLocations.includes(locationId);

  const handleFavorite = () => {
    const icon = isSaved ? '✖️' : '🖤️';
    const message = isSaved
      ? 'Platsen har tagits bort från dina favoriter.'
      : 'Platsen har lagts till bland dina favoriter!';

    if (isSaved) {
      removeSavedLocation(locationId);
      setFavoriteLocations(favoriteLocations.filter((id) => id !== locationId));
    } else {
      saveLocation(locationId);
      setFavoriteLocations([...favoriteLocations, locationId]);
    }

    toast(message, {
      icon: icon,
      position: 'top-center',
      style: {
        color: '#1E1E1E',
        background: '#FEFEFE',
        border: '1px solid #1E1E1E',
        padding: '16px',
      },
    });
  };

  return (
    <div className="flex w-full justify-between mb-6">
      <IconLinkButton
        text="Tillbaka"
        href="/"
        buttonStyle="px-4 w-fit md:absolute -left-28 md:top-4"
        icon={icon.ARROW}
        iconStyle="-rotate-90"
        target=""
      />
      <h1 className="text-3xl font-medium hidden md:block">{title}</h1>
      <div className="flex gap-3">
        <IconButton
          text={isSaved ? 'Ta bort favorit' : 'Lägg till favorit'}
          buttonStyle="px-4"
          hideTextOnMobile={true}
          icon={isSaved ? icon.HEART_FILLED : icon.HEART}
          onClick={handleFavorite}
          isDark={false}
        />
        <IconButton
          text="Dela"
          buttonStyle="px-4"
          hideTextOnMobile={true}
          icon={icon.SHARE}
          onClick={handleShare}
          isDark={false}
        />
      </div>
    </div>
  );
};

LocationDetailsUpperSection.propTypes = {
  title: PropTypes.string.isRequired,
  locationId: PropTypes.number.isRequired,
  handleShare: PropTypes.func.isRequired,
};

export default LocationDetailsUpperSection;
