import React from 'react';
import propTypes from 'prop-types';
import getIcon from '../../../../util/getIcon';
import icon from '../../../../config/icons';

const HeaderMenuButton = ({ onClick }) => {
  return (
    <button
      className="flex items-center space-x-2 bg-secondary h-10
      hover:bg-primary hover:text-secondary rounded-full border px-5 py-3 border-border"
      onClick={onClick}
      tabIndex={1}
    >
      {getIcon(icon.MENU)}
      <p className="text-sm font-semibold">Meny</p>
    </button>
  );
};

HeaderMenuButton.propTypes = {
  onClick: propTypes.func,
};

export default HeaderMenuButton;
