import React, { useState } from 'react';
import MobileFilter from '../phone/MobileFilter';
import PropTypes from 'prop-types';

const MainHeaderMobile = ({ isHeader, setHeader, setLocationIds }) => {
  return (
    <div
      className={`sm:hidden px-sm pt-2 bg-inherit
            transition-all duration-header
            ${isHeader ? 'max-h-[800px]' : 'max-h-[70px]'}
            `}
    >
      <MobileFilter
        isHeader={isHeader}
        setHeaderState={setHeader}
        setLocationIds={setLocationIds}
      />
    </div>
  );
};

MainHeaderMobile.propTypes = {
  isHeader: PropTypes.bool.isRequired,
  setHeader: PropTypes.func.isRequired,
  setLocationIds: PropTypes.func.isRequired,
};

export default MainHeaderMobile;
