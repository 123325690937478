import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Marker } from 'react-map-gl';
import getTagIcon from '../../util/getTagIcon';
import tagType from '../../config/tagType';

const getRandomRotation = (min, max) => Math.random() * (max - min) + min;

const MapMarker = ({
  id,
  locationTypeName,
  longitude,
  latitude,
  locationName,
  locationImage,
  onClick,
  className,
}) => {
  const randomDivRotation = useMemo(() => getRandomRotation(-5, 5), []);
  return (
    <Marker key={id} longitude={longitude} latitude={latitude}>
      <div
        onClick={() => onClick(id)}
        className={`w-fit bg-secondary text-primary
               p-2 swing
               flex flex-col items-center justify-between rounded-3xl
               transition-all hover:scale-105 hover:skew-y ${className}`}
        style={{
          rotate: `${randomDivRotation}deg`,
        }}
      >
        <img
          className="h-20 w-24 md:w-32 md:h-28 object-cover rounded-2xl"
          src={locationImage}
          alt={locationName}
        />
        <div className="hidden">
          {getTagIcon(locationTypeName, tagType.LOCATION_TYPE, '100%')}
        </div>
        <label className="font-semibld hidden">{locationName}</label>
      </div>
    </Marker>
  );
};

MapMarker.propTypes = {
  locationName: PropTypes.string.isRequired,
  locationImage: PropTypes.string.isRequired,
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  locationTypeName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default MapMarker;
