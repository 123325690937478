import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { RecoilRoot } from 'recoil';

import './theme/index.css';
import AppSwitcher from './components/generic/AppSwitcher';

const App = () => {
  const queryClient = new QueryClient();

  return (
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <AppSwitcher />
      </QueryClientProvider>
    </RecoilRoot>
  );
};

export default App;
