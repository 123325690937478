import React, { useState, useEffect } from 'react';

const UserWayToggle = () => {
  const [isWidgetActive, setWidgetActive] = useState(false);

  const toggleUserWayWidget = () => {
    if (window.userway && window.userway.widget) {
      if (!isWidgetActive) {
        window.userway.widget.open();
      } else {
        window.userway.widget.close();
      }
      if (window.userway.widget.triggerCustomFunction) {
        window.userway.widget.triggerCustomFunction();
      }
    }
    setWidgetActive(!isWidgetActive);
  };

  return (
    <div
      className="float-left flex items-left space-x-4 cursor-pointer pt-2 pr-5"
      onClick={toggleUserWayWidget}
    >
      <span className="text-gray-700 font-medium text-sm">Förenklat läge</span>
      <div className="relative inline-block w-12 h-6">
        <input
          type="checkbox"
          className="hidden"
          checked={isWidgetActive}
          readOnly
        />
        <div
          className={`block w-full h-full rounded-full transition-all duration-300 ${
            isWidgetActive ? 'bg-black' : 'bg-gray-300'
          }`}
        ></div>
        <div
          className={`absolute left-0 top-0 w-6 h-6 bg-white border-2 rounded-full transition-all duration-300 transform ${
            isWidgetActive ? 'translate-x-6 border-black' : 'border-gray-300'
          }`}
        ></div>
      </div>
    </div>
  );
};

export default UserWayToggle;
