import React from 'react';
import SkeletonBox from '../generic/loading/SkeletonBox';

const LocationListItemLoader = () => {
  return (
    <div
      className="size-full group
      transition-all ease-out p-2"
    >
      <SkeletonBox className="w-full aspect-square rounded-lg" />
      <div className="">
        <div className="w-full flex justify-between h-8 mt-2">
          <SkeletonBox className="w-20 h-5 rounded-3xl" />
          <SkeletonBox className="w-32 h-4 rounded-3xl" />
        </div>
        <div className="space-x-2 flex w-full">
          <SkeletonBox className="w-[38px] h-[26px] rounded-lg" />
          <SkeletonBox className="w-[38px] h-[26px] rounded-lg" />
          <SkeletonBox className="w-[38px] h-[26px] rounded-lg" />
          <SkeletonBox className="w-[38px] h-[26px] rounded-lg" />
        </div>
      </div>
    </div>
  );
};

export default LocationListItemLoader;
