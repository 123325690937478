import React from 'react';
import propTypes from 'prop-types';

const FilterDropdownWrapper = ({ isOpen, extraStyle, children }) => {
  return (
    <div
      className={`absolute
            bg-secondary rounded-2xl
            shadow-md
            overflow-hidden
            transition-all duration-filter 
            ${isOpen ? 'h-[600px] p-4 border opacity-100' : 'opacity-0 h-0'}
            ${extraStyle}`}
    >
      {children}
    </div>
  );
};

FilterDropdownWrapper.propTypes = {
  isOpen: propTypes.bool,
  extraStyle: propTypes.string,
  children: propTypes.any,
};

export default FilterDropdownWrapper;
